export const state = () => ( {

  lastUrl  : "",
  mathManagerCenter : "",
  domainData : {},
} )

export const mutations = {

  SET_DOMAIN_DATA( state, data ) {
    state.domainData = data;
  },

  SET_LAST_URL( state, url ) {
    state.lastUrl = url;
  },

  SET_MATH_MANAGER_CENTER( state, centerOid ) {
    state.mathManagerCenter = centerOid;
  },
}

export const actions = {

  setDomainData( { commit }, data ) {
    commit( "SET_DOMAIN_DATA", data );
  },

  setLastUrl( { commit }, url ) {
    commit( "SET_LAST_URL", url );
  },

  setMathManagerCenter( { commit }, centerOid ) {
    commit( "SET_MATH_MANAGER_CENTER", centerOid );
  },
}

export const getters = {

  getDomainData( state ) {
    return state.domainData;
  },

  getLastUrl( state ) {

    return state.lastUrl;
  },

  getMathManagerCenter( state ) {

    return state.mathManagerCenter;
  },
}
