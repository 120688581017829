const GET_CONFIG = "/api/envconfig/v1/portalEnvConfigApi_getPostingDisplayCount";
const GET_ENV_CONFIG = "/api/envconfig/v1/portalEnvConfigApi_getEnvConfig";

export const state = () => ( {

  // 페이지 카운트
  pageCountOptions: [],

  // 비밀번호 규칙
  pwdGenerateRuleObj : {},

  // 기타 ENV_CONFIG
  envConfigObj : {}
} )

export const mutations = {

  SET_POSTING_DISPLAY_COUNT(state, pageCountOptions) {
    state.pageCountOptions.push( pageCountOptions );
  },

  SET_INIT( state ) {
    state.pageCountOptions = [];
  },

  SET_ENV_CONFIG( state , envConfigInfo ) {
    state.envConfigObj[ envConfigInfo.envKey ] = envConfigInfo;
  },

  SET_PWD_GENERATE_RULE( state , pwdGenerateRule ){

    state.pwdGenerateRuleObj = pwdGenerateRule;
    state.pwdGenerateRuleObj.descr = $nuxt.$constant.PWD_GEN_RULE_DESCR[pwdGenerateRule.envValue].VALUE;

  }
}

export const actions = {

  // PageCount 정보를 가져와서 state 에 할당합니다.
  async setPostingDisplayCount({ commit }, payload ) {

    await this.$axios.post( GET_CONFIG ).then( (res) => {

      commit( "SET_INIT" );

      if( this.$validate.isEmpty( res.data ) ){
        return;
      }

      let envValue = res.data.envValue.split( "," );
      envValue.forEach( item => {

        let obj = {};
        obj.label = `${item}개씩`;
        obj.value = Number(item);

        commit( "SET_POSTING_DISPLAY_COUNT", obj );
      });
    });
  },

  // 비밀번호 생성 규칙을 가져와서 state 에 할당합니다.
  async setPwdGenerateRule( {commit} , envKey ) {

    if( $nuxt.$validate.isEmpty( envKey ) ){
      return;
    }

    let param = {
      envKey : envKey
    }

    await $nuxt.$axios.post( GET_ENV_CONFIG , param ).then( (res) => {

      if( this.$validate.isEmpty( res.data ) ){
        return;
      }

      commit( "SET_PWD_GENERATE_RULE" , res.data );
    });
  },

  // 환경설정 정보를 가져와서 state 에 할당합니다.
  async setEnvConfig( {commit} , envKey ){

    if( $nuxt.$validate.isEmpty( envKey ) ){
      return;
    }

    let param = {
      envKey : envKey
    }

    await $nuxt.$axios.post( GET_ENV_CONFIG , param ).then( (res) => {

      if( this.$validate.isEmpty( res.data ) ){
        return;
      }

      commit( "SET_ENV_CONFIG" , res.data );
    });

  }
}

export const getters = {
  getEnvConfig : ( state ) =>  ( envKey ) => {
        return state.envConfigObj[envKey];
  }

  ,
}
