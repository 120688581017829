import typeValidationUtils
  from '~/plugins/core/utils/validation/typeValidationUtils';
import formatUtils from '~/plugins/core/utils/formatUtils';
import dateUtils from '~/plugins/core/utils/dateUtils';
import { PORTAL_CONSTANT } from "~/constant/site/portal/portalConstant";

const portalUserUtils = (() => {

  const { isEmpty } = typeValidationUtils;
  const { formatDate } = dateUtils;

  return {

    /**
     * 로그인 혹은 SSO 후 사용자 정보를 스토어 값에 맞게 변환합니다.
     *
     * @param userInfo
     * @return {{orgName, loginLastDate: (string|*), userOid, isSelfCerted: (boolean|*), isNoDuplicateCheck: (boolean|*), googleOrgUnitId, userName, userId, isGoogleApiConnected: boolean, orgCode, googleUserInfo: *, loginAuditOid: string, isManager, userEmail: (string|*), userType, googleWorkspaceId, googleInsertStatusFlag: (string|*), userRoleOids: ([]|string|*), userBirthDay: (string|*), isSameOrgUnit: (boolean|*)}|{}}
     */
    convertUserInfoToLoginUser( userInfo ) {

      if ( isEmpty( userInfo ) ) {
        return {};
      }

      return {
        userId       : userInfo.userId,
        userOid      : userInfo.userOid,
        userName     : userInfo.userName,
        userEmail    : userInfo.userEmail,
        userRoleOids : userInfo.userRoleOids,

        orgName          : userInfo.orgName,
        orgCode          : userInfo.orgCode,
        userType         : userInfo.userType,
        userBirthDay     : this.makeBirthDay( userInfo ),

        isManager          : userInfo.isManager,
        isNoDuplicateCheck : userInfo.isNoDuplicateCheck,
        loginLastDate      : userInfo.loginLastDate,
        isSelfCerted       : userInfo.isSelfCerted,

        loginAuditOid      : "",
        lastLoginUserIp    : userInfo.lastLoginUserIp,

        // 구글 워크스페이스
        googleWorkspaceId      : userInfo.googleWorkspaceId,
        googleOrgUnitId        : userInfo.googleOrgUnitId,
        isSameOrgUnit          : userInfo.isSameOrgUnit,
        googleUserInfo         : userInfo.googleUserInfo,
        googleInsertStatusFlag : userInfo.googleInsertStatusFlag,
        isGoogleApiConnected   : PORTAL_CONSTANT.FLAG_YN.NO !== userInfo.isGoogleApiConnected,

        // 중복 로그인 확인 여부
        isDuplicateLoginCheck  : userInfo.isDuplicateLoginCheck,
      }
    },

    /**
     * birthDay 합침
     */
    makeBirthDay( userInfo ) {

      let birthYear = userInfo.userBirthYear;
      let birthMonth = userInfo.userBirthMonth;
      let birthDay = userInfo.userBirthDay;

      if ( isEmpty( birthYear ) || isEmpty( birthMonth ) || isEmpty( birthDay ) ) {
        return '';
      }

      return birthYear + birthMonth + birthDay;
    },

    /**
     * 가장 최근 사용자 상태 변경 이력의 날짜를 반환합니다.
     * 휴면 회원, 탈퇴 회원 관리에서 사용합니다.
     *
     * @param userWInfo
     * @return {*|string}
     */
    getLastUserStatusAuditDate( userWInfo ) {

      if ( isEmpty( userWInfo )
        || isEmpty( userWInfo.userStatusAuditList )
        || isEmpty( userWInfo.userStatusAuditList[0].statusDate ) ) {
        return "-";
      }

      return formatDate( userWInfo.userStatusAuditList[0].statusDate );
    },

    /**
     * 가장 최근 사용자 상태 변경 이력의 설명을 반환합니다.
     *
     * @param userInfo
     * @return {*|string}
     */
    getLastUserStatusAuditDescr( userInfo ) {

      if ( isEmpty( userInfo )
        || isEmpty( userInfo.userStatusAuditList )
        || isEmpty( userInfo.userStatusAuditList[0].descr ) ) {
        return "-";
      }

      return formatDate( userInfo.userStatusAuditList[0].descr );
    },

    /**
     * 포탈 정책에 따라 교직원용 아이디를 생성합니다.
     *  1. 소문자
     *  2. google workspace domain을 받음
     *
     *  @param {string} neisId - 나이스 아이디
     *  @return {string}
     * */
    getStaffUserId( neisId ) {

      if ( isEmpty( neisId ) ) {
        console.error( "포탈 회원 아이디 설정 오류 : 나이스 아이디가 없음" );
        return "";
      }

      return ( neisId + PORTAL_CONSTANT.WORKSPACE_ID_VALIDATION.DOMAIN ).toLowerCase();
    },

    /**
     * 관리자 > 회원 그룹 관리 > 회원정보관리 / 도내 교직원 정보 조회
     * 교육청, 교육지원청, 직속기관일 경우 관할을 반환합니다.
     */
    getControlNameVCByUser( userInfo ) {

      if ( isEmpty( userInfo ) || isEmpty( userInfo.extraInfoMap?.portalOrgInfo )
        || isEmpty( userInfo.extraInfoMap.portalOrgInfo.controlNameVC )
        || isEmpty( userInfo.extraInfoMap.portalOrgInfo.typeCode )
      ) {
        return '';
      }

      // T0060(직속기관), T0070(시도교육청), T0080(교육지원청)이 아닐 경우
      if ( PORTAL_CONSTANT.ORG_TYPE_DIRECT_INSTITUTE !== userInfo.extraInfoMap.portalOrgInfo.typeCode
        && PORTAL_CONSTANT.ORG_TYPE_EDU_OFFICE !== userInfo.extraInfoMap.portalOrgInfo.typeCode
        && PORTAL_CONSTANT.ORG_TYPE_EDU_SUPPORT_OFFICE !== userInfo.extraInfoMap.portalOrgInfo.typeCode) {
        return '';
      }

      return userInfo.extraInfoMap.portalOrgInfo.controlNameVC;
    },

    // 회원 구분 코드에 따라 label을 반환니다.
    getUserTypeLabel( userType ) {

      if ( isEmpty( userType ) ) {
        return '-';
      }

      let userTypeSet = Object.values( PORTAL_CONSTANT.USER_TYPE );
      let findUserType = userTypeSet.find( type => type.KEY === userType );

      return findUserType ? findUserType.LABEL : '-';
    },



  }
})();

export default portalUserUtils;
