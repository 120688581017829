
const GET_BOARD = "/api/manager/v1/portalManagerBoardApi_getBoardInfo";

const portalForbiddenCommon = {

  // 해당 게시판 금칙어 필터링 적용 확인
  async getBoardPropertyCheck( oid, boardType ) {

      let boardOid = oid;

      let boardParam = {
        boardOid: boardOid,
        fillProperty : true,
      };

      let isForbiddenCheck = false;

      // 해당 게시판 정보 다시 조회
      // : 관리자가 해당 게시판 권한 변경 후 재 로그인 하지 않으면 boardInfo.property 값 그대로 유지하기 때문에 조회 후 사용여부 확인
      await $nuxt.$axios.post( GET_BOARD, boardParam ).then( res => {

        if ( $nuxt.$validate.isEmpty( res.data ) || $nuxt.$validate.isEmpty( res.data.property ) ) {
          return isForbiddenCheck;
        }

        let boardInfoCheck = res.data;

        // 해당 게시판 금칙어 필터링 사용 여부 확인
        if ( boardInfoCheck.property.filteringYn === 'Y' ) {
            isForbiddenCheck = true;
        }

      });

      return isForbiddenCheck;
  },

  // 금칙어 valid 확인
  async forbiddenVaild( array ) {

    let forbiddenList = array;

    let stringTitle = forbiddenList.data.title.toString();
    let stringContents = forbiddenList.data.contents.toString();
    let stringTags = forbiddenList.data.tags.toString();

    if ( $nuxt.$validate.isNotEmpty( stringTitle ) || $nuxt.$validate.isNotEmpty( stringContents ) || $nuxt.$validate.isNotEmpty( stringTags ) ) {

      // 제목에 금칙어 포함
      if( $nuxt.$validate.isNotEmpty( stringTitle ) && $nuxt.$validate.isEmpty( stringContents ) && $nuxt.$validate.isEmpty( stringTags ) ){
        $nuxt.$util.rsAlertUtils.rsAlert( {
          title: "금칙어",
          contents: "제목 : [ " + stringTitle + " ] " + "\n" + "금칙어가 포함되어 있습니다."
        } );
      }
      // 내용에 금칙어 포함
      else if( $nuxt.$validate.isNotEmpty( stringContents ) && $nuxt.$validate.isEmpty( stringTitle ) && $nuxt.$validate.isEmpty( stringTags ) ){
        $nuxt.$util.rsAlertUtils.rsAlert( {
          title: "금칙어",
          contents: "내용 : [ " + stringContents + " ] " + "\n" + "금칙어가 포함되어 있습니다."
        } );
      }
      // 태그에 금칙어 포함
      else if( $nuxt.$validate.isNotEmpty( stringTags ) && $nuxt.$validate.isEmpty( stringTitle ) && $nuxt.$validate.isEmpty( stringContents ) ){
        $nuxt.$util.rsAlertUtils.rsAlert( {
          title: "금칙어",
          contents: "태그 : [ " + stringTags + " ] " + "\n" + "금칙어가 포함되어 있습니다."
        } );
      }
      // 제목, 태그에 금칙어 포함
      else if( $nuxt.$validate.isNotEmpty( stringTitle ) && $nuxt.$validate.isNotEmpty( stringTags ) && $nuxt.$validate.isEmpty( stringContents ) ){
        $nuxt.$util.rsAlertUtils.rsAlert( {
          title: "금칙어",
          contents:  "제목 : [ " + stringTitle + " ] " + "\n"
            + "태그 : [ " + stringTags + " ] " + "\n"
            + "금칙어가 포함되어 있습니다."
        } );
      }
      // 제목, 내용에 금칙어 포함
      else if( $nuxt.$validate.isNotEmpty( stringTitle ) && $nuxt.$validate.isNotEmpty( stringContents ) && $nuxt.$validate.isEmpty( stringTags ) ){
        $nuxt.$util.rsAlertUtils.rsAlert( {
          title: "금칙어",
          contents: "제목 : [ " + stringTitle + " ] " + "\n"
            + "내용 : [ " + stringContents + " ] " + "\n"
            + "금칙어가 포함되어 있습니다."
        } );
      }
      // 내용, 태그에 금칙어 포함
      else if( $nuxt.$validate.isNotEmpty( stringContents ) && $nuxt.$validate.isNotEmpty( stringTags ) && $nuxt.$validate.isEmpty( stringTitle ) ){
        $nuxt.$util.rsAlertUtils.rsAlert( {
          title: "금칙어",
          contents: "내용 : [ " + stringContents + " ] " + "\n"
            + "태그 : [ " + stringTags + " ] " + "\n"
            + "금칙어가 포함되어 있습니다."
        } );
      }
      else{
        $nuxt.$util.rsAlertUtils.rsAlert( {
          title: "금칙어",
          contents: "제목 : [ " + stringTitle + " ] " + "\n"
            + "내용 : [ " + stringContents + " ] " + "\n"
            + "태그 : [ " + stringTags + " ] " + "\n"
            + "금칙어가 포함되어 있습니다."
        } );
      }
      return false;
    }
    return true;
  },
}

export default portalForbiddenCommon
