import typeValidationUtils
  from '~/plugins/core/utils/validation/typeValidationUtils';
/**
 * 포탈 사이트용 Validation 유틸
 */
const portalValidationUtils = (() => {

  const { isEmpty } = typeValidationUtils;

  return {
    /**
     * NEIS 개인번호인지 여부를 확인합니다.
     * 10자리, 대문자+숫자
     *
     * @param {string} neisNo
     * @return {boolean}
     */
    isNeisNo( neisNo ) {

      if ( isEmpty( neisNo ) ) {
        return false;
      }

      return neisNo.length === 10 && /^(?=.*[A-Z])(?=.*[0-9])[A-Z0-9]+$/.test( neisNo );
    },
  };
})();

export default portalValidationUtils;