<template>
  <div class="menu-area">
    <div class="tit-area">
      <!-- gnb 클릭 시 해당 tit 로 변경 -->
      <h3 class="menu-tit">
        <span class="txt" v-text="firstMenu.name"></span>
      </h3>
    </div>
    <!-- 수리과학정보체험센터에서만 활성화 -->
    <div v-if="$constant.SYSTEM_OID.MATH === firstMenu.menuOid" class="math-btn-group">
      <el-button
        v-for="(center, i) in firstMenu.centerOidList" :key="i"
        @click="changeCenter(center)"
        :class="isActiveCenter(center)"
      >
        <span>
          {{ getCenterName(center) }}
        </span>
      </el-button>
    </div>
    <!--scroll-area-->
    <div class="scroll-element ht-custom" v-bar="{ preventParentScroll: true }">
      <el-row class="side-menu">
          <el-menu
            class="menu-list"
            ref="elMenu"
            :default-openeds="openList"
            :unique-opened="true"
          >
    <!--            :index="second.menuOid"-->
              <!-- index에 삼항연산자를 쓰는 이유 : iFrame일 경우 second.menuOid가 들어가면 Invalid prop 에러가 출력되기 때문 -->
            <!-- 2depth -->
              <el-submenu
                v-for="( second, i ) in childMenuList"
                v-show="showDisplayYn(second)"
                :key="second.menuOid"
                :index=" $constant.MENU_TYPE_FLAG.IFRAME.KEY !== firstMenu.menuTypeFlag ? second.menuOid : i + '' "
                :class="$validate.isNotEmpty( second.childTreeNodeList) ? '' : 'no-depth' ">
                <template slot="title">
                  <div class="sub-menu-tit is-pointer" :class="getMenuActive( second, 2 )">
                    <a class="link" v-text="second.name" @click="moveMenu( second )"></a>
                  </div>
                </template>
                  <el-menu-item-group class="sub-menu-list">
                    <!-- 3depth -->
                      <el-menu-item
                        v-for="third in second.childTreeNodeList"
                        v-show="showDisplayYn(third)"
                        :key="third.menuOid"
                        :index="third.menuOid"
                        >
                          <div class="sub-menu-tit is-pointer" :class="getMenuActive( third, 3 )">
                            <a class="link" v-text="third.name" @click="moveSubMenu( second, third )"></a>
                          </div>
                        <!-- 4depth -->
                        <el-menu-item-group>
                          <el-menu-item
                            v-for="fourth in third.childTreeNodeList"
                            v-show="showDisplayYn(fourth)"
                            :key="fourth.menuOid"
                            :index="fourth.menuOid"
                            class="sub-menu-list"
                          >
                            <div class="sub-menu-tit is-pointer">
                              <a class="link pl30" v-text="fourth.name" @click="moveFourthDepthMenu(fourth, third, second )"></a>
                            </div>
                          </el-menu-item>
                        </el-menu-item-group>
                      </el-menu-item>
                  </el-menu-item-group>
              </el-submenu>
          </el-menu>
      </el-row>
    </div>
  </div>
</template>
<script>

import { mapActions, mapGetters } from "vuex";

export default {
  components: {
  },

  props: {
    sideBarMenu : {
      type : Object,
      required : true,
      default : {
        name : "메인",
        childTreeNodeList : [],
      }
    }
  },

  data() {
    return {
      childMenuList : [],
      firstMenu : {},
      secondMenu : {},
      thirdMenu : {},

      openList : [],
    }
  },

  fetch() {

    this.setSideBarMenu();
  },

  computed:{
    ...mapGetters( {
      orgUnitsList : "portal/workspace/orgUnitsList",
    })
  },

  watch : {
    sideBarMenu() {

      this.setSideBarMenu();

    },

    // 2024.07.01 [SP20240701] 신다은 : 관리자 메뉴 권한 수정
    // 경로 변경 시 권한 세팅
    $route: {
        handler(to, from) {
            // console.log("$$$$$$$$$$$route 변경", to, from)

            // 같은 메뉴일 경우 기존 권한정보를 사용합니다.
            if ( from?.path && to?.path ) {

                const fromSplit = from.path.split( "/" );
                fromSplit.pop();
                const fromPath = fromSplit.join( "/" );

                const toSplit = to.path.split( "/" );
                toSplit.pop();
                const toPath = toSplit.join( "/" );

                // 마지막 경로를 제외한 나머지 경로가 같을 경우 같은 메뉴로 권한 세팅 X
                if ( fromPath === toPath ) {
                    return;
                }
            }

            // 현재 페이지의 권한정보 세팅
            this.roleMenuInfo();
        },
        immediate: true
    }
  },

  methods: {

    ...mapActions({
      roleMenuInfo : "portal/menuRole/roleMenuInfo",
    }),

    setSideBarMenu() {

      this.firstMenu = _.cloneDeep( this.sideBarMenu );

      this.openList = [];

      this.childMenuList = this.getChildTreeNodeList();

      if (this.$validate.isNotEmpty(this.childMenuList) ) {
        this.openList.push(this.childMenuList[0]?.menuOid);
      }

    },

    getChildTreeNodeList() {
      if ( this.$validate.isEmpty( this.sideBarMenu ) || this.$validate.isEmpty( this.sideBarMenu.childTreeNodeList ) ) {
        return [];
      }

      this.sideBarMenu.childTreeNodeList.forEach( menu => {
        menu.expand = true;
      } )

      // 20221130. submenu에 첫번째 메뉴는 menuOid가 없어서 Invalid prop 에러가 나서 제외


      return this.sideBarMenu.childTreeNodeList;
    },

    expandToggle( menu ) {
      menu.expand = !menu.expand;
      this.$forceUpdate();
    },

    /* 해당 페이지로 이동합니다. */
    moveMenu( second, parent ) {

      let first = {};

      if ( this.$validate.isNotEmpty( parent ) ) {
        this.firstMenu = parent;
      }

      first = this.firstMenu;

      let path = "";

      // 하위 메뉴가 없을 경우
      if ( this.$validate.isEmpty( second.childTreeNodeList ) ) {

        if ( this.$constant.MENU_TYPE_FLAG.IFRAME.KEY === first.menuTypeFlag ) {

        	const domain = this.localServerCheck(this.sideBarMenu.url );

	        path = domain + second.url;

	        this.$nuxt.$emit( "iframeMoveMenu", path );

        }
        else {

            // 이동할 URL이 없을 경우
            if ( this.$validate.isEmpty( second.url ) ) {
	           this.$util.rsAlertUtils.rsAlert( { title: second.name, contents: "메뉴 정보가 없습니다." } );
	           return;
            }

            path = first.url + second.url;

            this.$router.push( {
              path  : path,
            } );
        }
      }
      // 하위 메뉴가 있을 경우
      else {

        this.$forceUpdate();

        this.moveSubMenu( second, second.childTreeNodeList[ 0 ] );
      }
    },

    moveSubMenu( second, third ) {

      const first = this.firstMenu;

      if ( this.$validate.isEmpty( second.url ) || this.$validate.isEmpty( third.url ) ) {
        this.$util.rsAlertUtils.rsAlert( { title: "개발 중", contents: "해당 페이지는 개발 진행 중입니다." } );
        return;
      }

      // third에 자식노드가 있다면
      if ( this.$validate.isNotEmpty( third.childTreeNodeList ) ) {
        this.moveFourthDepthMenu( third.childTreeNodeList[0], third, second )
        return;
      }

      let path = "";
      let domain = first.url;

      if ( this.$constant.MENU_TYPE_FLAG.IFRAME.KEY === first.menuTypeFlag ) {

        domain = this.localServerCheck(this.sideBarMenu.url );

        // if ( this.$constant.SYSTEM_OID.MATH === first.menuOid ) {
        //   domain = process.env.SYSTEM.MATH.URL;
        // }
        // if ( this.$constant.SYSTEM_OID.LMS_ONTACT === first.menuOid ) {
        //   domain += "/lms/manager/ontact";
        // }
        if ( this.$constant.SYSTEM_OID.LMS_CONTACT === first.menuOid ) {
          domain += "/lms/manager/contact";
        }
        // if ( this.$constant.SYSTEM_OID.SURVEY === first.menuOid ) {
        //   domain = process.env.SYSTEM.SURVEY.URL;
        // }
        // if ( this.$constant.SYSTEM_OID.EBOOK === first.menuOid ) {
        //   domain = process.env.SYSTEM.EBOOK.URL;
        // }

      }

      // 상위 메뉴의 url이 없는 경우
      if ( this.$validate.isEmpty( second.url ) ) {

        path = domain + third.url;
      }
      else {

        path = domain + second.url + third.url;
      }

      if ( this.$constant.MENU_TYPE_FLAG.IFRAME.KEY === first.menuTypeFlag ) {
        this.$nuxt.$emit( "iframeMoveMenu", path );
      }
      else {
        this.$router.push( {
          path  : path,
        } );
      }
    },

    /**
     * 4depth에 있는 메뉴로 이동합니다.
     * 현재는 iframe > 수리과학 > 창의온 이관 자료 > 창의 공모전에서만 사용합니다.
     * @param fourth
     * @param third
     * @param second
     */
    moveFourthDepthMenu( fourth, third, second ) {

      if ( this.$validate.isEmpty( fourth.url ) ) {
        this.$util.rsAlertUtils.rsAlert( { title: "개발 중", contents: "해당 페이지는 개발 진행 중입니다." } );
        return;
      }

      // 현재는 iframe만 4depth를 사용합니다.
      if ( this.$constant.MENU_TYPE_FLAG.IFRAME.KEY !== this.firstMenu.menuTypeFlag ) {
        return;
      }

      let domain = this.localServerCheck(this.sideBarMenu.url );

      if ( this.$constant.SYSTEM_OID.LMS_CONTACT === this.firstMenu.menuOid ) {
        domain += "/lms/manager/contact";
      }

      let path = domain + second.url + third.url + fourth.url;

      this.$nuxt.$emit( "iframeMoveMenu", path );
    },

    getMenuActive( menu, depth ) {

      let pathName = this.$route.path;

      // console.log ( "1111111111111111111111" );
      // console.log ( "menu : " , menu );
      // console.log ( "depth : " , depth );
      // console.log ( "pathName : ", pathName );
      // console.log ( "systemOid : ", this.$route.query.systemOid );
      // console.log ( "1111111111111111111111" );

      if ( this.$route.query.menuOid ) {

        pathName = this.$store.state.portal.iframe.lastUrl;

        if ( this.$constant.SYSTEM_OID.MATH === this.$route.query.menuOid ) {
          // 도메인의 center 때문에 센터 관리 메뉴가 무조건 활성화됨. -> 도메인 제거
          // pathName = pathName.replace( process.env.SYSTEM.MATH.URL, '' );
          pathName = pathName.replace( this.sideBarMenu.url, '' );
        }

      }

      let menuUrl = menu.url;

      if ( this.$validate.isEmpty( menuUrl ) ) {
        return;
      }

      if ( 3 === depth ) {

        if ( this.$route.query.menuOid ) {

          if ( this.$constant.SYSTEM_OID.MATH === this.$route.query.menuOid ) {

            if ( -1 < pathName.indexOf( "/centerMgmt" ) ) {
              pathName = pathName.replace( '/mathSciences/manager/centerMgmt', '' );
            }
            else if ( -1 < pathName.indexOf( "/programMgmt" ) ) {
              pathName = pathName.replace( '/mathSciences/manager/programMgmt', '' );
            }
            else {
              pathName = pathName.replace( '/mathSciences/manager', '' );
            }
          }

        }

      }

      if ( -1 < pathName.indexOf( menuUrl ) ) {

        if ( 2 === depth ) {
          this.secondMenu = _.cloneDeep( menu );
        }

        if ( 3 === depth ) {
          this.thirdMenu = _.cloneDeep( menu );
        }
        return "is-active";
      }
      else {
        return "";
      }
    },

    getCenterName( centerOid ) {
      return this.$constant.CENTER_OID_OBJECT[centerOid].VALUE2.split( "," )[0];
    },

    changeCenter( centerOid ) {

      this.$store.dispatch( "portal/iframe/setMathManagerCenter", centerOid );
      this.$nuxt.$emit( "iframeMoveMenu", this.localServerCheck(process.env.SYSTEM.MATH.URL) + "/mathSciences/manager/main?centerOid=" + centerOid );
    },

    isActiveCenter( centerOid ) {
      let result = '';
      const mathManagerCenter = this.$store.state.portal.iframe.mathManagerCenter;

      if ( centerOid === mathManagerCenter ) {
        result = 'is-active';
      }

      return result;
    },

    localServerCheck( url ) {

      if ( "portal_local" !== process.env.SITE_NAME) {
        return url;
      }

      return url + this.portNum( url );
    },

    portNum( url ) {
      if ( url.includes( "learning" ) ) {
        return ":1130";
      }
      else if ( url.includes( "survey" ) ) {
        return ":1120";
      }
      else if ( url.includes( "contents" ) ) {
        return ":1110";
      }
      else if ( url.includes( "center" ) ){
        return ":2220";
      }
      else if ( url.includes( "note" ) ){
        return ":2230";
      }
    },

    // 권한문제로 displayYn이 'N'인 메뉴도 가져오기 때문에  displayYn이 'Y'인 메뉴만 보여주도록 처리합니다.
    showDisplayYn( menu ) {

      // iframe 메뉴의 경우 displayYn 컬럼이 없기 때문에 무조건 보여지도록 처리
      if ( this.$validate.isEmpty( menu.displayYn ) ) {
        return true;
      }

      return this.$constant.FLAG_YN.YES === menu.displayYn;
    },
  },
}
</script>
<style scoped>
</style>
