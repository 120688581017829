import browserUtils from '~/plugins/core/utils/browserUtils';

/**
 * ui event 유틸
 * ui에서 event를 실행합니다.
 *
 */
const eventUiUtils = (() => {

  return {

    /**
     * transitionEnd Detecting Event
     */
    whichTransitionEvent( className = undefined ) {
      let el = document.querySelector( `.${ className }` );

      if ( className === undefined ) {
        el = document.body.createElement( 'dommy' );
      }
      // browser
      const transitions = {
        'transition'       : 'transitionend',
        'OTransition'      : 'oTransitionEnd',
        'MozTransition'    : 'transitionend',
        'WebkitTransition' : 'webkitTransitionEnd'
      }

      for ( let transition in transitions ) {
        if ( el.style[ transition ] !== undefined ) {
          return transitions[ transition ]
        }
      }

    },

    // scroll top event
    scrollToTop() {

      window.scrollTo( 0, 0 );
    },

    /**
     * - scroll Event (IE 호환)
     *
     *  참조) selector(param)은 className(string) or tagName(string)으로 넘겨줘야합니다.
     */
    scrollToEl( selector = 'body', duration = 600 ) {

      if ( !selector ) return;

      // 'scrollBehavior'를 지원하는 브라우저인지 체크합니다
      const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;

      // 브라우저에서 지원할때 적용
      const nativeSmoothScrollTo = el => {
        window.scroll( {
          behavior : 'smooth',
          left     : 0,
          top      : el.getBoundingClientRect().top + window.pageYOffset,
        } );
      };

      // 지원하지 않는 브라우저에 적용
      const smoothScrollTo = ( to, duration ) => {
        const el = document.scrollingElement || document.body;
        const start = el.scrollTop;
        const change = to - start;
        const startDate = +new Date();
        // t = current time
        // b = start value
        // c = change in value
        // d = duration
        const easeInOutQuad = ( t, b, c, d ) => {
          t /= d / 2;
          if ( t < 1 ) return c / 2 * t * t + b;
          t--;
          return -c / 2 * ( t * ( t * -2 ) - 1 ) + b;
        };

        const animateScroll = _ => {
          const currentDate = +new Date();
          const currentTime = currentDate - startDate;
          el.scrollTop = parseInt( easeInOutQuad( currentTime, start, change, duration ) );
          if ( currentTime < duration ) {
            requestAnimationFrame( animateScroll );
          }
          else {
            el.scrollTop = to;
          }
        };

        animateScroll();
      };


      const el = document.querySelector( selector ) || document.getElementsByTagName( selector );

      if ( el ) {

        // 지원하는 브라우저는 nativeSmoothScrollTo를 실행합니다
        if ( supportsNativeSmoothScroll ) {
          nativeSmoothScrollTo( el );
        }
        else {
          smoothScrollTo( el.offsetTop, duration );
        }
      }
    },

    /**
     * Scroll event를 최적화 합니다.
     *
     *  참조) 화면프레임수 이하로만 콜백함수를 호출합니다
     */
    optimizeAnimation( callback ) {
      let ticking = false;

      return () => {
        if ( !ticking ) {
          ticking = true;
          requestAnimationFrame( () => {
            callback();
            ticking = false;
          } )
        }
      }
    },

    // 사파리환경에서 Material icon을 임포트시킵니다
    importMaterialWithSafari() {

      if ( !browserUtils.isIos() ) {
        return;
      }

      const materialLink = document.createElement( 'link' );
      materialLink.rel = "stylesheet";
      materialLink.type = "text/css";
      materialLink.href = "https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp";
      document.head.appendChild( materialLink );
    },
  }

})();

export default eventUiUtils;