import axios from "axios";
import Cookie from "js-cookie";
import portalApiUrl from "~/constant/site/portal/portalApiUrl";

const PATH_LOGIN = '/portal/user/login/user_login';
const PATH_MAIN = '/portal/user/main/user_main';

const URL_SSO_USER = "/portal/sso/sso_user";
const URL_AUDIT_LOGIN_INSERT = "/api/user/v1/portalUserLoginAuditApi_insert";

const URL_CHECK_SESSION = "/api/user/v1/checkSessionStatus"

export default async function ( { route, redirect, store } ) {

  const KWE_USERID = Cookie.get( "KWE_USERID" );

  let originalPath = route.fullPath;

  // 로그인 중복 확인 설정이 되어 있는 상태에서 로그인 된 사용자만 중복 로그인 체크를 합니다.
  if ( $nuxt.$portalAuth.isLogin() && $nuxt.$store.state.common.login.loginUser.isDuplicateLoginCheck ) {
    const isDuplicated = await store.$axios.post( URL_CHECK_SESSION );
  }

  if ( !KWE_USERID ) {
	  return;
  }

  // 로그인이 되어있을 경우
  if ( $nuxt.$portalAuth.isLogin() ) {

	 // 로그인화면인 경우라면 메인화면으로 이동
	 if ( PATH_LOGIN === originalPath ) {
		 return redirect( PATH_MAIN );
	 }

    return;
  }

  // 스토어에 사용자 아이디나 LUO 쿠키가 있다면 새창으로 접속했을 것으로 판단.
  let isNewWindow = $nuxt.$validate.isNotEmpty( $nuxt.$store.state.common.login.loginUser.userId ) || $nuxt.$validate.isNotEmpty( Cookie.get ("LUO") );

  // 로그인이 안되어있는데 해당 쿠키가 존재한다면
  if ( KWE_USERID ) {

    await handleSsoLogin( );

	  // 세션생성후 로그인화면이었다면 메인으로 이동
    if ( PATH_LOGIN === originalPath ) {
		  return redirect( PATH_MAIN );
	  }

    return;
  }


  /**
   * SSO 로그인 처리 함수
   */
  async function handleSsoLogin( ) {

      const userInfo = await axios.post( URL_SSO_USER, {} );

      if ( $nuxt.$validate.isEmpty( userInfo )
           || $nuxt.$validate.isEmpty( userInfo.data ) ) {

          return;
      }

      // 중복 로그인인 경우
      if ( userInfo.data.isDuplicateLogin ) {

        const content = `${ userInfo.data.loginLastDate || '-' }에 \n ${ userInfo.data.lastLoginUserIp || '-' }에서 접속 중입니다. \n 기존 사용자를 종료 후 계속 진행하시겠습니까?`

        const willInvalidSession = await $nuxt.$util.rsAlertUtils.rsAlert( {
          title: "중복 로그인 감지",
          contents: content,
          useCancelBtn: true
        } );

        // 닫기 혹은 alert 창 밖을 누르는 경우 로그인 X
        if ( !willInvalidSession ) {

          Cookie.remove( "KWE_USERID", { path : "/", domain: process.env.DOMIAN } );
          Cookie.remove( "KWE_LOGIN_TYPE", { path : "/", domain: process.env.DOMIAN } );
          Cookie.remove( "LUO", { path : "/" } );
          Cookie.remove( "LURL", { path : "/" } );
          Cookie.remove( "UAC" , { path : "/" } );

          return;
        }

        const result = await invalidatePreviousSessionId( userInfo.data );

        // 이전 로그인 사용자 세션을 끊지 못한 경우
        if ( !result ) {

          $nuxt.$util.reAlertUtils.reAlert( {
            title : "로그인 실패",
            contents: "기존 사용자 종료에 실패했습니다."
          } );
          return;
        }
      }

      loginSuccess( userInfo.data );
  }

  /**
   * 로그인 성공 처리
   */
  function loginSuccess( data ) {

    const loginUser = store.$portalUtil.portalUserUtils.convertUserInfoToLoginUser( data );
    store.dispatch( "common/login/loginUser", loginUser );


	  // 새창일때는 이력을 쌓지 않도록 처리
    if ( !loginUser.isNoDuplicateCheck && !isNewWindow ) {
	    //console.log ( "이력 로그 실시" );
      saveAuditLogin();
    }
  }

  /**
   * 로그인 접속정보를 가져와서 로그인 정보 저장
   */
  async function saveAuditLogin() {

    let result = await store.$util.visitUtils.getUserVisitInfo();

    if ( store.$validate.isNotEmpty( result ) ) {
      	await insertAuditLogin( result );
    }
  }

  /**
   * 로그인 접속정보 서버 API 호출
   */
  async function insertAuditLogin( visitInfo ) {

    if ( store.$validate.isEmpty( visitInfo ) ) {
      return;
    }

    const {data} = await store.$axios.post( URL_AUDIT_LOGIN_INSERT, visitInfo );
    store.dispatch( "common/login/setLoginAuditOid", data );

  }

  async function invalidatePreviousSessionId( userInfo ) {

    const param = {
      userOid : userInfo.userOid,
      customField4 : userInfo.prevJsessionId,
      extraInfoMap : {
        currentJsessionId : userInfo.currentJsessionId
      }
    }

    const { data } = await store.$axios.post( portalApiUrl.USER.LOGIN.INVALIDATE_PREVIOUS_SESSION, param );

    return data;

  }
}

