import typeValidationUtils
  from '~/plugins/core/utils/validation/typeValidationUtils';
/**
 * Date 유틸
 *
 */
const dateUtils = (() => {

  const { isEmpty } = typeValidationUtils;
  
  return {
    // 날짜 분까지 format
    formatDateTimeMin( value ) {
      if ( isEmpty( value ) || typeof value !== "string" ) {
        return;
      }

      value = value.substring( 0, 16 );
      value = value.replace( "T", " " );

      do value = value.replace( "-", "." );
      while ( -1 < value.indexOf( "-" ) );

      return value;
    },

    formatTimeMinSec( value ) {
      if ( isEmpty( value ) || typeof value !== "string" ) {
        return;
      }

      value = value.substring( 11, value.indexOf( "." ) );

      do value = value.replace( "-", "." );
      while ( -1 < value.indexOf( "-" ) );

      return value;
    },

    // YYYY-MM-DD format
    formatDate( value ) {
      if ( isEmpty( value ) || typeof value !== "string" ) {
        return;
      }

      value = value.substring( 0, 10 );
      return value;
    },

    // YYYY.MM.DD format
    delimFormatDate( value, delim ) {
      if ( isEmpty( value ) ||  isEmpty( delim ) ) {
        return;
      }

      let tempDate = $nuxt.$moment( value );

      let year = tempDate.year();
      let month = tempDate.month() + 1;
      let date = tempDate.date();

      if ( month < 10 ) {
        month = "0" + month;
      }

      if ( tempDate.date() < 10 ) {
        date = "0" + tempDate.date();
      }

      return year + delim + month + delim + date;
    },

    // MM-DD
    formatDateMMDD( value ) {
      if ( isEmpty( value ) || typeof value !== "string" ) {
        return;
      }
      value = value.substring( 5, 10 );
      return value;
    },

    // Date Type 을 받아 YYYY-MM-DD 형태의 String 으로 Return (DatePicker 날짜 string으로 변환시 사용)
    formatDateToString( value ) {

      let year = value.getFullYear();
      let month = value.getMonth() + 1;
      let date = value.getDate();

      if ( month < 10 ) {
        month = "0" + month;
      }

      if ( value.getDate() < 10 ) {
        date = "0" + value.getDate();
      }

      return year + "-" + month + "-" + date;
    },

    formatDateToString2( value ) {
      let date =
        this.leadingZeros( value.getFullYear(), 4 ) +
        "-" +
        this.leadingZeros( value.getMonth() + 1, 2 ) +
        "-" +
        this.leadingZeros( value.getDate(), 2 ) +
        " ";

      let time =
        this.leadingZeros( value.getHours(), 2 ) +
        ":" +
        this.leadingZeros( value.getMinutes(), 2 );

      return date + time;
    },

    leadingZeros( n, digits ) {
      var zero = "";
      n = n.toString();

      if ( n.length < digits ) {
        for ( let i = 0; i < digits - n.length; i++ ) zero += "0";
      }
      return zero + n;
    },

    //ie의 경우 new Date( "YYYY-MM-DD HH:MM:SS" )가 안되어서 포맷에 맞게 '2019/09/09 17:22' 로 변환
    ieDateConversion( value ) {
      return value.replace( "-", "/" ); // '2019/09/09 17:22'
    },

    //한국 timezone으로 현재시간 return
    getToday() {
      return $nuxt.$moment().tz( 'Asia/Seoul' ).format( 'YYYY-MM-DD' );
    },

    getTodayWithTime() {
      return $nuxt.$moment().tz( 'Asia/Seoul' ).format( 'YYYY-MM-DD HH:mm:ss' );
    },

    parseDate( date ) {
      if ( !date ) {
        return;
      }

      return $nuxt.$moment( date ).tz( 'Asia/Seoul' ).format( 'YYYY-MM-DD' );
    },

    parseDateWithTime( date ) {
      if ( !date ) {
        return;
      }

      return $nuxt.$moment( date ).tz( 'Asia/Seoul' ).format( 'YYYY-MM-DD HH:mm:ss' );
    },

    /* 초를 제외한 나머지*/
    parseDateWithTime2( date ) {
      if ( !date ) {
        return;
      }
      return $nuxt.$moment( date ).tz( 'Asia/Seoul' ).format( 'YYYY-MM-DD HH:mm' );
    },

    /* 시간만 반환 한다. */
    parseDateForTime( date ) {
      if ( !date ) {
        return;
      }
      return $nuxt.$moment( date ).tz( 'Asia/Seoul' ).format( 'HH:mm' );
    },

    parseDateWithZeroTime( date ) {
      if ( !date ) {
        return;
      }

      return $nuxt.$moment( date ).tz( 'Asia/Seoul' ).format( 'YYYY-MM-DD 00:00:00' );
    },

    compareDates( date1, date2) {
      let moment1 = $nuxt.$moment(this.parseDate(date1));
      let moment2 = $nuxt.$moment(this.getToday());

      if ( this.isNotEmpty( date2 ) ) {
        moment2 = $nuxt.$moment(this.parseDate(date2));
      }

      let diff = moment1.diff(moment2)

      let diff2 = diff / (1000 * 3600 * 24);

      return diff2;
    },

    // 일자 출력
    getDate( date ) {
      return $nuxt.$moment( date ).date();
    },

    // 월 출력
    getMonth( date ) {
      return $nuxt.$moment( date ).month() + 1;
    },

    // 연도 출력
    getYear( date ) {
      return $nuxt.$moment( date ).year();
    },

    /**
     * 연도에서 뒤에서 2째 자리 자름
     */
    getYearBack2( date ) {

      const year = this.getYear( date );
      return year.toString().slice(-2);
    },

    /**
     * 현재월의 종료일 구하기
     * ex ) 현재 2022년 6월이라면 마지막일인 '2022-06-30' 을 반환합니다.
     */
    getEndDateOfThisMonth() {
      let today = new Date();
      let lastDate = new Date( today.getFullYear(), today.getMonth() + 1, 0 );
      return this.parseDate( lastDate );
    },

    /**
     * "수,목,금" 으로 가져온 요일 정보를 Date 객체식으로 변환합니다.
     * @param str
     * return 0 ~ 6 의 int 값을 가지는 Array
     */
    getDayOfWeek( str ) {
      let daysArr = str.split( ',' );
      const _self = this;
      daysArr.forEach( ( item, index ) => {
        daysArr[ index ] = _self.switchDayFromStrtoInt( item );
      } );
      return daysArr;
    },
    switchDayFromStrtoInt( str ) {
      let day;
      switch ( str ) {
        case "일" :
          day = 0;
          break;
        case "월":
          day = 1;
          break;
        case "화":
          day = 2;
          break;
        case "수":
          day = 3;
          break;
        case "목":
          day = 4;
          break;
        case "금":
          day = 5
          break;
        case "토":
          day = 6;
          break;
      }
      return day;
    },

    switchDayFromIntToStr( int ) {
      let day;
      switch ( int ) {
        case 0 :
          day = '일';
          break;
        case 1 :
          day = "월";
          break;
        case 2:
          day = "화";
          break;
        case 3:
          day = "수";
          break;
        case 4:
          day = "목";
          break;
        case 5:
          day = "금"
          break;
        case 6:
          day = "토";
          break;
      }
      return day;
    },

  };
})();

export default dateUtils;