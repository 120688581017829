import typeValidationUtils from '~/plugins/core/utils/validation/typeValidationUtils';
import formatUtils from '~/plugins/core/utils/formatUtils';

/**
 *
 * Posting 유틸
 *
 */
const postingUtils = (() => {

  const { isEmpty } = typeValidationUtils;

  const FILE_TYPE_CONTENTS = "FWFT00CO"; // 본문 컨텐츠의 파일 리스트

  return {

    /**
     * 공지사항 no를 세팅합니다.
     * noticeLevel이 1일 경우? 공지라벨 : 숫자
     *
     * @param list
     * @param listCount
     * @param startIndex
     * @param noticeLabel
     */
    settingNoticeNoToList ( list, listCount, startIndex, noticeLabel = "공지" ) {

      if ( isEmpty( list ) ) {
        return;
      }

      list.forEach( ( item, index ) => {

        const isNotice = 1 === item.noticeLevel;
        item.no = isNotice ? noticeLabel : formatUtils.settingNo( listCount, startIndex, index );
      })
    },

    /**
     * 에디터에서 img 태그의 src를 추출해 fileInfo에 맞춘 데이터로 가공합니다.
     * src가 /storage/storageFile_fileDown/{fileName}/{storageFIleUid} 와 같은 포맷인 경우에만, 추출 가능합니다.
     *
     * @param content
     * @param uploadedImageMap
     * @return {{fileName: *, fileSize, fileExt: *, storageFileUid: *, fileType: string}[]}
     */
    getEditorImageList( content, uploadedImageMap ) {

      if ( !content || !uploadedImageMap ) {
        return [];
      }

      const domParser = new DOMParser();
      const parsedContent = domParser.parseFromString( content, "text/html" );
      const imgs = Array.from( parsedContent?.querySelectorAll( "img" ) ) ?? [];

      const contentsFileList = imgs.map( ( item ) => {

        const srcArray = item.attributes[0].value.split( "/" );     // return : [ "", "storage", "storageFile_fileDown", {fileName} , {storageFileUid} ] , ex : ["", "storage", "storageFile_fileDown", "이미지_최종_v.0.1.3.png", "20230525000000000001" ]

        const storageFileUid = srcArray.pop();                    // return : {storageFileUid} , ex : "20230525000000000001"
        const fileName = srcArray.pop();                          // return : {fileName} , ex : "이미지_최종_v.0.1.3.png"
        const fileExt = fileName?.split( "." ).pop();                // return ex : "png"

        return {
          fileName,
          storageFileUid,
          fileExt,
          fileType: FILE_TYPE_CONTENTS,
          fileSize: uploadedImageMap[storageFileUid] ?? -1,
        }
      } );

      return contentsFileList;
    }
  };
})();

export default postingUtils;