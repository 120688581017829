<template>
	<footer id="manager-footer" class="footer mgmt-footer">
		<div class="inner default-w">
			<div class="txt-area">
				<p class="copyright">
          Gangwon State Education Science & Information Institute. All Right Reserved.
				</p>
			</div>
		</div>
	</footer>
</template>
<script>
export default {}
</script>
<style scoped>
</style>
