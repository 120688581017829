const URL_ORG_CODE_LIST = "/api/user/v1/portalUserOrgCodeApi_list"

export const state = () => ( {
  organizationList : []
} )

export const mutations = {
	SET_ORGANIZATION_LIST( state, organizationList ) {
		state.organizationList = organizationList;
	},
}

export const actions = {
	async listOrganization( { commit } ) {

	  // 관리자가 운영 중인 기관 코드만 가져옵니다.
		let param = {};
		param.useYn = $nuxt.$constant.FLAG_YN.YES;

		await $nuxt.$axios.post( URL_ORG_CODE_LIST, param ).then( res => {

			if ( $nuxt.$validate.isEmpty( res.data ) ) {

				return;
			}

			commit( "SET_ORGANIZATION_LIST", res.data );
		} );
	},

  async listOrganizationByName( { commit }, name ) {

    if ( $nuxt.$validate.isEmpty( name ) ) {
      return;
    }

    // 관리자 타입 + 즐겨찾기 정보 채워옴
    let param = {
      name ,
      allowLikeQuery : true,
    };

    await $nuxt.$axios.post( URL_ORG_CODE_LIST, param ).then( res => {

      commit( "SET_ORGANIZATION_LIST", res.data );
    } );
  }
}

export const getters = {
	organizationList( state ) {

		if ( $nuxt.$validate.isEmpty( state.organizationList ) ) {
			$nuxt.$store.dispatch( "portal/organization/listOrganization" );
		}

		return state.organizationList;
	},

  organizationListBySName : ( state ) => async ( name ) => {

    await $nuxt.$store.dispatch( "portal/organization/listOrganizationByName", name );

    return state.organizationList;
  }
}
