import typeValidationUtils
  from '~/plugins/core/utils/validation/typeValidationUtils';
import { PORTAL_CONSTANT } from "~/constant/site/portal/portalConstant";

/**
 * 포털 권한 메뉴 유틸
 */
const portalRoleMenuUtils = (() => {

  const { isEmpty, isNotEmpty } = typeValidationUtils;

  /**
   * childTreeNodeList를 하나의 리스트로 만들기 위함
   *
   */
  function getChildTreeNodeList( menuListByRole ) {

    let childTreeNodeList = [];

    menuListByRole.forEach( first => {

      // 1depth
      if ( isEmpty( first.childTreeNodeList ) ) {

        childTreeNodeList.push( first );
      }
      // 2depth
      else {

        first.childTreeNodeList.forEach( second => {

          // 2depth
          if ( isEmpty( second.childTreeNodeList ) ) {

            childTreeNodeList.push( second );
          }
          // 3depth
          else {

            // [YP20240627] 2024-06-27 하위 노드가 숨김 처리된 경우 노드리스트에 값 추가
            const nonDisplayedChildNodeParent= second.childTreeNodeList.filter( node => $nuxt.$constant.FLAG_YN.NO === node.displayYn );

            if ( isNotEmpty( nonDisplayedChildNodeParent ) && nonDisplayedChildNodeParent.length === second.childTreeNodeList.length ) {
              childTreeNodeList.push( second );
              return;
            }

            second.childTreeNodeList.forEach( third => {

              // 3depth
              if ( isEmpty( third.childTreeNodeList ) ) {

                childTreeNodeList.push( third );
              }
              // 4depth
              else {

                // [YP20240627]
                const nonDisplayedChildNodeParent= third.childTreeNodeList.filter( node => $nuxt.$constant.FLAG_YN.NO === node.displayYn );

                if ( isNotEmpty( nonDisplayedChildNodeParent ) && nonDisplayedChildNodeParent.length === third.childTreeNodeList.length ) {
                  childTreeNodeList.push( third );
                  return;
                }

                third.childTreeNodeList.forEach( fourth => {

                  childTreeNodeList.push( fourth );
                } );
              }
            } );
          }
        } );
      }
    } );
    return childTreeNodeList;
  }

  function unusualHandling( childTreeNodeList, result, url ) {

    // console.log('unusualHandling', childTreeNodeList );
    // console.log('unusualHandling', result );
    // console.log('unusualHandling', url );

    let fullPath = isEmpty( url ) ? $nuxt.$route.fullPath : url ;

    // commonBoard 공통 게시판
    if ( fullPath.indexOf( 'menuOid' ) > -1 ) {

      // console.log('@@@@@@@@@@@@@@@@@@@@@@', url );
      let menuOid = '';
      if ( url ) {
        const fullUrl = new URL( url );
        menuOid = fullUrl.searchParams.get( 'menuOid' );

        // console.log('@@@@@@@@@@@@@', menuOid );
      }
      else {
        menuOid = $nuxt.$route.query.menuOid;
      }

      // 예외1. 교육연구 > 교육정보화 연구대회 탭 이동 관련 추가사항
      if ( menuOid === PORTAL_CONSTANT.EDU_SEARCH.CONTEST_INTRO.OID ) {
        menuOid = PORTAL_CONSTANT.EDU_SEARCH.CONTEST_REF_ROOM.OID;
      }

      result = childTreeNodeList.find( child => menuOid === child.menuOid );
    }

    if ( isEmpty( result ) ) {

      if ( fullPath.includes( 'userSchedule_board_view' ) ) {
        fullPath = '/portal/user/adminActivate/schedule/userSchedule_list';
      }

      result = childTreeNodeList.find( child => fullPath.includes( child.url ) );
    }
    return result;
  }

  // 로그인전 사용자 페이지별 권한 가져오기
  function roleBeforeLoginUserMenuInfo( url ) {
    let menuListByRole = $nuxt.$store.getters[ "portal/menuRole/beforeLoginUserMenuListByRole" ];

    // console.log('##### menuListByRole' , menuListByRole)
    if ( isEmpty( menuListByRole ) ) {
      return;
    }

    let childTreeNodeList = getChildTreeNodeList( menuListByRole );

    let result = {};
    if( url ) {
      result = unusualHandling( childTreeNodeList, result, url );
    }
    else {
      result = unusualHandling( childTreeNodeList, result );
    }
    // console.log( ' roleBeforeLoginUserMenuInfo result : ', result );
    // console.log( ' roleBeforeLoginUserMenuInfo result.roleMenuInfo : ', result?.roleMenuInfo );

    return result?.roleMenuInfo;
  }

  // 사용자 페이지별 권한 가져오기
  function roleUserMenuInfoByPage( url ) {

    // 사용자 메뉴 리스트 ( 교육연구, 교육활동, ... )
    let menuListByRole = $nuxt.$store.getters[ "portal/menuRole/userMenuListByRole" ];
    // console.log( '[portalUserCommon] - roleUserMenuInfoByPage - userMenuList : ', menuListByRole );

    if ( isEmpty( menuListByRole ) ) {
      return;
    }

    let childTreeNodeList = getChildTreeNodeList( menuListByRole );

    let result = {};
    if ( url ) {
      result = unusualHandling( childTreeNodeList, result, url );
    }
    else {
      result = unusualHandling( childTreeNodeList, result );
    }

    // console.log( ' roleUserMenuInfoByPage result : ', result );
    // console.log( ' roleUserMenuInfoByPage result.roleMenuInfo : ', result?.roleMenuInfo );

    return result?.roleMenuInfo;
  }

  // 관리자 페이지별 권한 가져오기
  function roleManagerMenuInfoByPage() {

    let menuListByRole = $nuxt.$store.getters[ "portal/menuRole/adminMenuListByRole" ];

    if ( isEmpty( menuListByRole ) ) {
      return;
    }

    let childTreeNodeList = getChildTreeNodeList( menuListByRole );

    // child.url.split 시 0번째에 ''값이 들어감
    // 2024.07.01 [SP20240701] 신다은 : 관리자 메뉴 권한 수정 iFrame 메뉴 제외
    let result = childTreeNodeList.find( child =>
      $nuxt.$constant.MENU_TYPE_FLAG.IFRAME.KEY !== child.menuTypeFlag
      && $nuxt.$route.path.includes( child.url.split( "/" )[ 1 ] )
    );

    // console.log("$$$$$$$ result", result?.name, result )

    return result?.roleMenuInfo;
  }


  return {

    /**
     * displayYn이 Y인 메뉴만 가져옵니다.
     * depth가 1인경우 Header에서 1depth도 숨김처리한 메뉴를 보이지 않도록 처리합니다.
     * breadcrumb의 경우 2depth부터 숨김처리한 메뉴를 보이지 않도록 해야합니다.
     *
     * @param menuList
     * @param depth
     * @return {{}|*}
     */
    getOnlyDisplayMenuList( menuList , depth ) {

      if ( isEmpty( menuList ) ) {
        return {};
      }

      if ( 1 === depth ) {
        menuList = menuList.filter( menu => $nuxt.$constant.FLAG_YN.YES === menu.displayYn );
      }

      menuList.forEach( menu => {

        if( isEmpty( menu.childTreeNodeList ) ) {
          return;
        }

        menu.childTreeNodeList = this.getOnlyDisplayMenuList( menu.childTreeNodeList, 1 );
      });

      return menuList;
    },

    // 현재 위치를 기준으로 메뉴별 role 객체 리턴
    async getRoleMenuInfo() {

      let roleMenuInfo = {};
      const fullPath = $nuxt.$route.fullPath; // 현재 위치

      // 로그인 유저
      const loginUser = $nuxt.$store.state.common.login.loginUser;

      if ( isEmpty( loginUser.userId ) ) {

        roleMenuInfo = roleBeforeLoginUserMenuInfo();
      }
      else {

        if ( fullPath.indexOf( '/user/' ) > -1 ) {

          roleMenuInfo = roleUserMenuInfoByPage();
          // console.log( 'user! ', roleMenuInfo );
        }
        else if ( fullPath.indexOf( '/manager/' ) > -1 ) {

          roleMenuInfo = roleManagerMenuInfoByPage();
          // console.log( 'manager! ', roleMenuInfo );
        }
      }

      // 해당 role이 가진 메뉴가 아닐 경우 메인으로 리턴
      if ( isEmpty( roleMenuInfo ) && !fullPath.includes( "/search_main" ) &&!fullPath.includes( "/iframeMgmt" ) ) {

        $nuxt.$util.rsAlertUtils.rsAlert( { title: "권한 없음", contents: "해당 페이지 접근 권한이 없습니다." } ).then( () => {
          $nuxt.$router.push( "/portal/user/main/user_main" );
        })
      }

      return roleMenuInfo;
    },

    // 검색 후 상세페이지 이동 시 호출
    getRoleMenuInfoBySearch( url ) {

      // 로그인 유저
      const loginUser = $nuxt.$store.state.common.login.loginUser;
      const fullPath = url; // 현재 위치

      if ( isEmpty( loginUser.userId ) ) {

        return roleBeforeLoginUserMenuInfo( url );
      }
      else if ( fullPath.indexOf( '/user/' ) > -1 ) {

        return roleUserMenuInfoByPage( url );
        // console.log( 'user! ', roleMenuInfo );
      }
      else if ( fullPath.indexOf( '/manager/' ) > -1 ) {

        return roleManagerMenuInfoByPage( url );
        // console.log( 'manager! ', roleMenuInfo );
      }

      return {};
    },


  }
})();

export default portalRoleMenuUtils;