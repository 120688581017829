import typeValidationUtils
  from '~/plugins/core/utils/validation/typeValidationUtils';

/**
 * 복사 유틸
 *
 */
const copyUtils = (() => {

  const { isEmpty } = typeValidationUtils;

  return {

    /**
     * 클립보드에 텍스트를 저장합니다.
     *
     * navigator.clipboard 지원이 가능한 브라우저일 경우 navigator.clipboard 사용
     * 그 외 exceCommand 사용하여 클립보드에 저장합니다.
     * https://developer.mozilla.org/en-US/docs/Web/API/Navigator/clipboard#browser_compatibility
     *
     * @param text
     */
    clipboardCopy( text ) {

      if ( isEmpty( text ) ) {
        return;
      }

      // navigator.clipboard 사용 가능 시
      // 지원하는 브라우저가 한정적이라 만일 지원하지 않을 경우 execCommand 사용
      if ( navigator.clipboard ) {
        navigator.clipboard.writeText( text );
        return;
      }

      // 그 외 execCommand 사용
      const input = document.createElement( 'input' );
      input.setAttribute( 'value', text );
      document.body.appendChild( input );
      input.select();
      document.execCommand( 'copy' );
      document.body.removeChild( input );

    },

  }
})();

export default copyUtils;