import typeValidationUtils
  from '~/plugins/core/utils/validation/typeValidationUtils';
/**
 * 엑셀 Util
 *
 */
const excelUtils = (() => {

  const { isEmpty } = typeValidationUtils;

  return {

    /**
     * 엑셀 API 호출시 form data에 맞게 object 파라미터를 array로 변환합니다.
     * [ "inputUser=admin"... ]
     *
     * @param param
     * @return {string[]|*[]}
     */
    convertExcelParam( param ) {

      return Object.entries(param).map(([key, value]) => `${key}=${value}`);
    },

    /**
     * 엑셀을 다운로드 합니다.
     *
     * @param {string} url
     * @param {Object} param
     */
    downloadExcel( url, param ) {

      if ( isEmpty( url ) ) {
        return;
      }

      let form = `<form action='${url}?' method='post'>`;

      param.forEach( item => {

        const stringSplit = item.split( "=" );

        // dynamicQuery 가 들어왔을 경우
        if ( "dynamicQuery" === stringSplit[0] ) {
          // 첫번째 "=" 등호 이후의 문자를 찾아 '를 &#39;로 대체합니다.
          // &#39;로 대체하는 이유는 form 내의 value 속성에 '(작은따옴표)가 있을 경우 특수 문자로 간주하고 해석할 수 있기 때문에
          // HTML 엔터티를 사용하여 이스케이프 처리를 해야합니다.
          let dynamicQuery = item.replace(/^[^=]*=/, '');
          dynamicQuery = dynamicQuery.replace(/'/g, '&#39;');
          stringSplit[1] = dynamicQuery;
        }

        form += "<input type='hidden' name='" + stringSplit[0]
          + "' value='" + stringSplit[1] + "'/>";
      } );

      form += "</form>";
      $( form ).appendTo( "body" ).submit().remove();
    },

  };
})();

export default excelUtils;