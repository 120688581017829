import typeValidationUtils
  from '~/plugins/core/utils/validation/typeValidationUtils';
import browserUtils from '~/plugins/core/utils/browserUtils';

/**
 * 파일 Util
 *
 */
const fileUtils = ( () => {

  const { isEmpty, isNotEmpty } = typeValidationUtils;
  const { isIE } = browserUtils;

  // @ 로 호출 시 이미지가 나오지 않아 /_nuxt/로 부르도록 처리
  const NO_IMAGE = "/_nuxt/assets/core/image/noImage/no_image.png";

  return {

    /**
     * StrorageFileUid로 썸네일을 만들어서 반환.
     * size는 width_height 형태로 보내면 됨
     * ex) this.$util.fileUtils.getThumbnailPath ( programInfo.fileList[0].storageFileUid , 313_234);
     *
     *  참조) selector(param)은 className(string) or tagName(string)으로 넘겨줘야합니다.
     *
     *  2021-10-01 Woong 파일 확장자 추가
     *  thumbnailUtils.java의 makeThumbnail() 메소드에서 파일 확장자명을 구분하여 메소드가 분화되어 있음.
     *  이미지 파일의 경우가 아닐 때엔 원본 파일의 확장자명을 같이 보내주어야함.
     *
     * @param storageFileUid
     * @param size
     * @param noImage
     * @param fileExt
     * @return {string}
     */
    getThumbnailPath( storageFileUid, size = "100_100", noImage = "", fileExt = "png" ) {

      let thumbnail = NO_IMAGE;

      if ( isNotEmpty( noImage ) ) {
        thumbnail = noImage;
      }

      if ( isEmpty( storageFileUid ) ) {
        return thumbnail;
      }

      if ( isEmpty( size ) ) {
        size = "100_100";
      }

      if ( isEmpty( fileExt ) ) {
        fileExt = "png";
      }

      return "/thumbnail_view/" + storageFileUid + "/" + fileExt + "/" + size;
    },

    /**
     * 유튜브 썸네일을 가져옵니다.
     *
     * @param url
     * @return {string}
     */
    getYoutubeThumbnail( url ) {

      if ( !url.includes( "?v=" ) ) {
        return "";
      }

      const arrUrl = url.split( "?v=" );
      return "https://img.youtube.com/vi/" + arrUrl[ 1 ] + "/hqdefault.jpg";
    },

    /**
     * 첨부파일을 다운로드합니다.
     *
     * @param fileName
     * @param storageFileUid
     */
    downloadFile( fileName, storageFileUid ) {

      if ( isEmpty( fileName ) || isEmpty( storageFileUid ) ) {
        return;
      }

      location = `/storage/storageFile_fileDown/${ fileName }/${ storageFileUid }`;
    },

    /**
     * template 폴더 내부에 있는 파일을 다운로드합니다.
     *
     * @param {string} id : dom id
     * @param {string} fileNameWithExt : 확장자를 포함한 파일명
     */
    downloadTemplateFile( id, fileNameWithExt ) {

      if ( isEmpty( id ) || isEmpty( fileNameWithExt ) ) {
        return;
      }

      if ( !isIE() ) {

        const down = document.createElement( "a" );
        down.href = document.getElementById( id ).getAttribute( "src" );
        down.download = fileNameWithExt;
        document.body.appendChild( down );
        down.click();
        document.body.removeChild( down );

        return;
      }

      const link = document.createElement( "a" );
      link.setAttribute( "id", id );
      link.setAttribute( "href", `/template/${fileNameWithExt}` );
      link.setAttribute( "download", fileNameWithExt );
      link.innerHTML = fileNameWithExt;
      link.click();
      link.remove();
    },

  };
})();

export default fileUtils;