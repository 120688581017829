import Cookies from "js-cookie";

const portalAuth = {

  /**
   * store 에 로그인 정보가 있으면 true / 없으면 false 를 반환합니다.
   * 스토어랑 쿠키에 값이 없으면 로그인이 안되었다고 판단한다.
   */
  isLogin() {

    let loginUser = $nuxt.$store.state.common.login.loginUser;

    // console.log ( "스토어 : " + $nuxt.$validate.isNotEmpty( loginUser.userId )  );
	// console.log ( "쿠키 : " + $nuxt.$validate.isNotEmpty( Cookies.get ("LUO") ) );

    return $nuxt.$validate.isNotEmpty( loginUser.userId ) && $nuxt.$validate.isNotEmpty( Cookies.get ("LUO") );
  },
  isNotLogin() {
    return !this.isLogin();
  },

  /**
   * 로그아웃 처리
   */
  async logout() {
    await $nuxt.$store.dispatch('common/login/logout');
  },
  /**
   * 로그아웃 처리후 로그인페이지로 이동
   */
  async logoutAndGoLoginPage() {
    await $nuxt.$store.dispatch('common/login/logoutAndGoLoginPage');
  },

}

export default ( context, inject ) => {
  inject( "portalAuth", portalAuth );
};
