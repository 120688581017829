<template>
	<div>
		<!-- :header="calendarHeader" -->
		<fullCalendar :options="calendarOptions" ref="fullCalendar"/>

	</div>
</template>
<script>
import "./calendar.scss";
import "@fullcalendar/core/vdom"; // solves problem with Vite
import fullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import momentTimezonePlugin from "@fullcalendar/moment-timezone";

export default {
    components: {
        fullCalendar, // make the <FullCalendar> tag available
    },
    props     : {

        // 사이즈 업데이트 입니다
        sizeUpdate: {
            type    : Boolean,
            required: false,

        },

        thisMonthScheduleData: {
            type    : Array,
            required: false,
            default : () => {
                return [];
            },
        },

    },
    data () {
        return {
            calendarOptions: {
                plugins      : [ dayGridPlugin, interactionPlugin, momentTimezonePlugin ],
                headerToolbar: {
                    left  : 'prev, next, today',
                    center: 'title',
                    right : '',
                },
                initialView  : 'dayGridMonth',
                editable     : false,
                // selectable      : true,
                weekends        : true,
                timeZone        : 'Asia/Seoul',
                locale          : 'ko',
                datesSet        : this.handleMonthChange,     // 월을 변경할 경우 실행
                events          : [],                         // 이벤트 데이터
                eventClick      : this.handleEventClick,      // 이벤트 클릭했을 시
                viewRender      : this.viewRender,
                dayMaxEvents    : 20,
                eventRender     : this.eventRender,
                fixedWeekCount  : false,
                displayEventTime: false,
                buttonText      : {
                    today: '이번달',
                },
                dateClick       : this.handleDateClick,
            },

            // calenderApi
            calendarApi: null,

            dates: {
                fromDate: '',
                toDate  : '',
            },

            calendarYM: '',

            isFirst: true,

        };
    },

    mounted () {

        // calenderApi 할당
        this.calendarApi = this.$refs.fullCalendar.getApi();

        if ( undefined !== this.$route.query?.yearMonthDate ) {
            this.calendarYM = this.$route.query?.yearMonthDate;
            this.calendarApi.gotoDate(
                this.$util.dateUtils.formatDate( this.$route.query?.yearMonthDate ) );
        }
        else if ( undefined !== this.$route.query?.yearMonth ) {
            this.calendarApi.gotoDate(
                this.$util.dateUtils.formatDate( this.$route.query?.yearMonth ) );
        }

    },

    computed: {},

    watch: {
        thisMonthScheduleData: {
            deep: true,
            handler ( newVal ) {
                this.calendarOptions.events = [];
                this.calendarOptions.events = this.thisMonthScheduleData;
            },
        },

        dates: {
            deep: true,
            handler ( newVal ) {
                this.$emit( 'dateChange', newVal );
            },
        },

        sizeUpdate () {
            this.updateSize();
        },
    },

    methods: {

        handleDateClick ( arg ) {
            this.$emit( 'insertVisible', arg.dateStr );
        },

        handleEventClick ( arg ) {
            this.$emit( 'getDetail', arg.event.id );

        },

        // 월을 변경할 경우 실행
        handleMonthChange ( arg ) {

            // console.log("arg : ", arg)
            const calendarYearMonth = arg.view.title;
            if ( this.$validate.isEmpty( calendarYearMonth ) ) {
                return;
            }

            const yearData = calendarYearMonth.substr( 0, 4 ); // 2022

            let monthData = '';
            if ( 8 === calendarYearMonth.length ) {
                monthData = '0' + calendarYearMonth.substr( 6, 1 ); // 01 ~ 09
            }
            else if ( 9 === calendarYearMonth.length ) {
                monthData = calendarYearMonth.substr( 6, 2 ); // 10 ~ 12
            }
            this.calendarYM = this.$util.dateUtils.parseDate( yearData + '-' + monthData );

            if ( this.$validate.isNotEmpty( this.$route.query?.yearMonthDate ) ) {
                this.$router.push(
                    { path: this.$route.fullPath, query: { yearMonthDate: this.calendarYM } } );
            }
            else {
                this.$router.push(
                    { path: this.$route.fullPath, query: { yearMonth: this.calendarYM } } );
            }

            this.startDate = _.cloneDeep( $nuxt.$util.dateUtils.formatDate( arg.startStr ) );
            this.endDate = _.cloneDeep( $nuxt.$util.dateUtils.formatDate( arg.endStr ) );

            this.dates.fromDate = this.startDate;
            this.dates.toDate = this.endDate;
        },

        // size update calender
        updateSize () {
            this.calendarApi.updateSize();
            this.$emit( 'updateSize', false );
        },

        getCalendarDate () {

            let date = [];
            date.push( this.startDate );
            date.push( this.endDate );

            return date;
        },

        getFullCalendarDate () {
            return this.calendarApi.getDate();
        },
    },
};
</script>
<style lang="scss"></style>
