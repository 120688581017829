import portalApiUrl from '~/constant/site/portal/portalApiUrl';

const URL_CODE_LIST              = "/api/manager/v1/portalManagerCode_listAll";
/**
 * Code 관리 script
 *
 * @property getRankClassificationList  - 신분분류값을 codeType 으로 갖는 코드 목록을 불러옵니다.
 *
 * @version 1.0 2022.01.05 cheeeeze : 신규 생성
 * @version 1.0 2022.01.11 ted      : 유저도 사용할 수 있도록 파라메터 추가
 *
 */
const portalCodeCommon = {

  /**
   * 신분분류값을 codeType 으로 갖는 코드 목록을 불러옵니다.
   * 신분류는 첫번째 Grade으로 판단
   * 2024.07.25 James getRankCodeList => getFirstGradeList 
   *
   * @param {boolean} isUser = false - 사용자 여부
   * @returns {Promise<void>}
   */
  async getFirstGradeList( isUser= false ) {

    let param = {
      codeType : '신분분류'
    };

    let result = [];

    let url = isUser ? portalApiUrl.USER.CODE.LIST_ALL_RANK_CODE : URL_CODE_LIST;

    await $nuxt.$axios.post( url, param ).then( res => {

      if ( res.data.length === 0 || res.status !== 200 ) {
        return;
      }
      result = res.data;
    });
    return result;
  },


  async getSecondPositionList( grade , isUser = false ) {

    //select * from TB_CODE WHERE parent_code_key = 'PS014';
    let param = {
      parentCodeKey : grade,
    };
    let result = [];

    let url = isUser ? portalApiUrl.USER.CODE.LIST_ALL_IDENTITY_CODE : URL_CODE_LIST;

    await $nuxt.$axios.post( url, param ).then( res => {

      if ( res.data.length === 0 || res.status !== 200 ) {
        return;
      }
      result = res.data;
    });
    return result;
  },

}
export default portalCodeCommon;
