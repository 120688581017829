import typeValidationUtils
  from '~/plugins/core/utils/validation/typeValidationUtils';
/**
 * format 유틸
 *
 */
const formatUtils = (() => {

  const { isEmpty } = typeValidationUtils;

  return {
    /**
     * 전화번호에 하이픈을 넣습니다.
     *
     * @param phone {string}
     * @return {string}
     */
    formatPhone( phone ) {

      if ( isEmpty( phone ) ) {
        return "";
      }

      return phone.replace( /[^\d-]/g, '' ).replace( /^(\d{3})(\d{4})(\d{4})$/g, `$1-$2-$3` );
    },

    /**
     * 생년월일에 하이픈을 넣습니다.
     *
     * @param day {string}
     * @return {string}
     */
    formatBirthday( day ) {

      if ( isEmpty( day ) || day.length !== 8) {
        return "";
      }

      return `${ day.slice(0,4) }-${ day.slice(4,6) }-${ day.slice(6,8) }`;
    },

    /**
     * 첨부파일 용량 계산
     *
     * @param {number} bytes
     * @return {string}
     */
    formatFileSize( bytes ) {

      if ( bytes <= 0 ) {
        return "0 Bytes";
      }
      const decimals = 2;

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = [ "Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB" ];

      const i = Math.floor( Math.log( bytes ) / Math.log( k ) );

      return parseFloat( ( bytes / Math.pow( k, i ) ).toFixed( dm ) ) + " " + sizes[ i ];
    },

    /**
     * 숫자만 반환합니다.
     *
     * @param value{*|string}
     * @return {*|string}
     */
    extractNumber( value ) {

      if ( isEmpty( value ) ) {
        return "";
      }

      return value.replace( /[^0-9]/g, '' );
    },

    /**
     * 숫자의 천 단위 마다 콤마를 찍습니다.
     *
     * @param {number} num
     * @return {string}
     */
    addCommas( num ) {

      if ( isEmpty( num ) ) {
        return "0";
      }

      const str = num.toString();
      const reg = /(-?\d+)(\d{3})/;
      let newStr = str;

      while ( reg.test( newStr ) ) {
        newStr = newStr.replace( reg, "$1,$2" );
      }

      return newStr;
    },

    // 목록에서 No를 setting 해줍니다.
    settingNo ( listCount, startIndex, index ) {
      return this.addCommas( listCount - ( startIndex + index ) + 1 );
    },

    /**
     * no 프로퍼티가 없는 목록에 천단위콤마 No 를 할당합니다.
     *
     * @param list
     * @param listCount
     * @param startIndex
     */
    settingNoWithCommaToList ( list, listCount, startIndex ) {

      if ( isEmpty( list ) ) {
        return;
      }

      list.forEach( ( item, index ) => {
        item.no = this.settingNo( listCount, startIndex, index );
      } )
    },

    /**
     * 문자열의 첫, 끝 글자만 남기고 masking(*) 처리합니다.
     *
     * @param str {string}
     * @param length {number} : 앞 뒤로 자르고 싶은 자리수
     * @return {string}
     */
    maskingStr( str, length = 1 ) {

      if ( isEmpty( str ) ) {
        return "";
      }

      // 문자열 길이가 length * 2 보다 짧으면, 모든 문자를 마스킹 처리
      if ( str.length <= length * 2 ) {
        return str[0] + '*'.repeat( str.length - 1 );
      }

      // 문자열의 앞부분과 뒷부분
      const start = str.slice( 0, length );
      const end = str.slice( -length );

      // 가운데 부분을 마스킹 처리
      const middle = '*'.repeat( str.length - 2 * length );

      return start + middle + end;
    },

    /**
     * map을 list 형태로 변경하여 반환합니다.
     *
     * @param mapData
     * @param {string} keyName
     * @param {string} valueName
     * @return {{}[]|*[]}
     */
    convertMapToList( mapData, keyName, valueName ) {

      if ( isEmpty( mapData ) ) {
        return [];
      }

      return Object.entries( mapData ).map( ( [ key, value ] ) => ( {
        [keyName]: key,
        [valueName]: value
      } ) );

    },
  };
})();

export default formatUtils;