/**
 * 포탈에서 사용하는 Store 정보
 */
const __storeMenuModule           = "portal/menu";          // 메뉴
const __storeOrganizationModule   = "portal/organization";  // 기관 코드

const portalStoreName = {
  // EnvConfig
  menu: {
    action : {
      list            : __storeMenuModule + "/getMenuList",
      adminListByRole : __storeMenuModule + "/getAdminMenuListByRole",
      userListByRole  : __storeMenuModule + "/getUserMenuListByRole",
      adminFavList    : __storeMenuModule + "/getAdminFavList"
    },
    getter : {
      list            : __storeMenuModule + "/menuList",
      adminListByRole : __storeMenuModule + "/adminMenuListByRole",
      userListByRole  : __storeMenuModule + "/userMenuListByRole",
      adminFavList    : __storeMenuModule + "/adminFavMenuList"
    }
  },

  // loading
  org : {
    action : {
      list          : __storeOrganizationModule + "/listOrganization",
      listByName    : __storeOrganizationModule + "/listOrganizationByName",
    },
    getter : {
      list          : __storeOrganizationModule + "/organizationList",
      listByName    : __storeOrganizationModule + "/organizationListBySName",
    }
  }

};

export default portalStoreName;
