import { PORTAL_CONSTANT } from "~/constant/site/portal/portalConstant";
const URL_EXTERNAL_ROLE_LIST = "/api/external/v1/portalExternalRoleApi_innerSystemRoleList";

/**
 * 포탈에서 외부 시스템 권한 관리하는 script
 *
 * @property getSystemRoleListAll        - 포탈 또는 타 시스템 권한 목록 호출
 * @property getPortalRoleList        - 포탈 시스템 1개의 권한 목록 호출
 * @property getExternalRoleList      - 외부 시스템 1개의 권한 목록 호출
 *
 * @version 1.0 2022.01.10 cheeeeze : 신규생성
 *
 */
const portalExternalRole = {

  // 검색에서 시스템 구분 리스트 필요시 사용
  setSystemList() {

    let adminMenuList = $nuxt.$store.getters[ "portal/menuRole/adminMenuListByRole" ];

    if ( $nuxt.$validate.isEmpty( adminMenuList ) ) {
      return;
    }

    let systemList = [];
    adminMenuList.forEach( system => {

      let obj = {
        label : system.name,
        value : system.menuOid,
      }

      systemList.push( obj );
    } );

    return systemList;
  },

  /**
   * 시스템 목록 ( 위 setSystemList() 의 반환값 ) 를 파라미터로 받고
   * 타 시스템 중 권한 관리가 없는
   * 설문, 전자책, 교수학습지원센터, 구글워크스페이스를 목록에서 제외합니다.
   *
   * @param systemList
   * @returns {*[]|*}
   */
  removeUnnecessarySystem( systemList, removeList ) {

    if ( $nuxt.$validate.isEmpty( systemList ) ) {
      return [];
    }

    removeList.filter( remove => {
      systemList = systemList.filter( item => {

       return item.value !== remove;
      })
    });

    return systemList;
  },



  // 시스템 이름을 가져옵니다.
  getSystemName( systemOid ) {

    if ( $nuxt.$validate.isEmpty( systemOid ) ) {
      return;
    }

    let adminMenuList = $nuxt.$store.getters[ "portal/menuRole/adminMenuListByRole" ];

    if ( $nuxt.$validate.isEmpty( adminMenuList ) ) {
      return;
    }

    let systemName = adminMenuList.find( system => system.menuOid === systemOid );

    return systemName.name;
  },

  /**
   * 파라미터로 받은 시스템의 권한 목록을 호출합니다.
   * 자신인 포탈 시스템인 경우 내부 API 를 호출하고
   * 타 시스템인 경우 외부 API 를 호출합니다.
   */
  getSystemRoleListAll( systemOid ) {

    // PORTAL 과 HOMEPAGE => portal API 호출
    if ( $nuxt.$constant.SYSTEM_OID.PORTAL === systemOid
      || $nuxt.$constant.SYSTEM_OID.HOMEPAGE === systemOid ) {

      return this.getPortalRoleList( systemOid );

    }
    else {

      return this.getExternalRoleList( systemOid );
    }

  },
  /**
   * 포탈 시스템의 전체 roleList 를 호출합니다.
   * @param systemOid
   * @returns {Promise<AxiosResponse<any>|*[]>}
   */
  async getPortalRoleList( systemOid ) {

    const URL_ROLE_LIST = "/api/manager/v1/portalManagerRoleApi_roleListAll";
    const param = {
      systemOid : systemOid,
      autoAssignYn : 'N', // 회원자동지정 권한(학생,일반,학부모,교직원 같은 )이 아닌 것만 검색
    }

    let response = await $nuxt.$axios.post( URL_ROLE_LIST, param );

    if ( response.data === null || response.data.length === 0 ) {
      return [];
    }

    return response.data;
  },

  /**
   * 외부 시스템의 role List 를 부르는 api 를 호출
   * @param systemOid
   */
  async getExternalRoleList( systemOid ) {

    // console.log( `${systemOid} 시스템 권한 목록 가져오기!`);

    // external API 부르기
    const URL_SYSTEM_ROLE = "/api/external/v1/portalExternalRoleApi_systemRole";
    const param = {
      systemOid : systemOid,
    }

    let response = await $nuxt.$axios.post( URL_SYSTEM_ROLE, param );

    if ( response.data === null || response.data.length === 0 ) {
      return [];
    }

    // console.log( response.data );
    return response.data[ systemOid ];
  },


  /**
   * userId 로 전체 시스템의 권한 정보를 가져옵니다.
   */
  async getUserRoleListFromAllSystem( userId ) {

    // 1) 포탈 + 홈페이지 시스템 권한 정보 호출
    let portalRoleList = await this.getInnerSystemRoleList(); // [ { ... }, { ... }, ... ]

    // 2) 나머지 시스템 권한 정보 호출
    let otherRoleList = await this.getExternalSystemRoleList( userId ); // [ { ... }, { ... }, ... ]

    // 3) 병합
    let totalRoleList = this.merge( portalRoleList, otherRoleList );

    // // 4) 등록일시 순 내림차순 정렬
    // this.orderByInputDate( totalRoleList );

    // 5) 이름이랑 No 넣어줌
    this.setProperty( totalRoleList );

    return totalRoleList;

  },

  // 등록일시 순으로 내림차순 정렬합니다.
  // orderByInputDate( roleList ) {
  //
  //   if ( $nuxt.$validate.isEmpty( roleList ) ) {
  //     return;
  //   }
  //
  //   roleList.sort( function( a, b ) {
  //     if ( a.inputDate > b.inputDate ) {
  //       return 1;
  //     }
  //     if ( a.inputDate < b.inputDate ) {
  //       return -1;
  //     }
  //     return 0;
  //
  //   });
  // },

  /**
   * 포털 / 홈페이지 권한 호출
   * router 에 userOid 와 userType 이 있어야함.
   *
   * @returns {Promise<*[]>}
   */
  async getInnerSystemRoleList() {

    let result = [];
    let systemOidList = [
      PORTAL_CONSTANT.SYSTEM_OID.PORTAL,    // 포탈
      PORTAL_CONSTANT.SYSTEM_OID.HOMEPAGE,  // 홈페이지
    ];

    let hashMap = {
      userOid       : $nuxt.$route.query.userOid,
      userType      : $nuxt.$route.query.userType,
      systemOidList : systemOidList,
    }

    await $nuxt.$axios.post( URL_EXTERNAL_ROLE_LIST, hashMap ).then( res => {

      if ( res.status !== 200 || res.data.length === 0 ) {
        return result;
      }
      // console.log( '포탈 + 홈페이지 role List ', res.data );

      result = res.data;

    } );

    return result;
  },


  /**
   * 타시스템 role List 를 호출하여 배열에 담습니다.
   * @returns {Promise<*[]>}
   */
  async getExternalSystemRoleList( userId ) {

    // 타시스템 role List 를 전부 담을 arr
    let result = [];

    /** 수리과학 */
    let mathRoleList = await this.getMathRoleList( userId );

    /** LMS */
    let lmsRoleList = await this.getLmsRoleList( userId );

    // 수리과학 담기
    if ( mathRoleList.length > 0 ) {
      mathRoleList.forEach( item => result.push( item ) );
    }

    // LMS( 원격교육연수원, 교직원집합연수원 ) 담기
    if ( lmsRoleList.length > 0 ) {
      lmsRoleList.forEach( item => result.push( item ) );
    }


    return result;
  },

  /**
   *  내부 권한 목록과 타시스템 권한 목록을 합칩니다.
   *
   * @param portalRoleList
   * @param otherRoleList
   * @returns {*[]}
   */
  merge( portalRoleList, otherRoleList ) {

    let totalList = [];

    if ( $nuxt.$validate.isNotEmpty( portalRoleList ) ) {
      portalRoleList.forEach( item => totalList.push( item ) );
    }

    if ( $nuxt.$validate.isNotEmpty( otherRoleList ) ) {
      otherRoleList.forEach( item => totalList.push( item ) );
    }

    return totalList;
  },

  // list 에서 사용할 No 와 systemName 을 더해줍니다.
  setProperty( list ) {

    if ( list.length === 0 ) {
      return [];
    }

    list = this.setNo( list );

    list = this.setSystemName( list );

    return list;
  },

  setNo( list ) {

    list.forEach( ( item, index ) => {
      item.no = index + 1;
    } );
    return list.reverse();
  },

  setSystemName( list ) {

    list.forEach( item => {

      item.systemName = PORTAL_CONSTANT.SYSTEM_OID_LIST.find( system => system.key === item.systemOid ).value ?? '-';

    } );

    return list;
  },

  /**
   * 사용자의 수리과학정보센터 내의 권한을 호출합니다.
   *
   * @param userId
   * @returns {Promise<*[]>}
   */
  async getMathRoleList( userId ) {

    let mathRoleList = await this.systemRoleByUserId( PORTAL_CONSTANT.SYSTEM_OID.MATH, userId );

    if ( mathRoleList.length > 0 ) {

      // systemOid 값 부여
      mathRoleList.forEach( item => item.systemOid = PORTAL_CONSTANT.SYSTEM_OID.MATH );

    }
    return mathRoleList;
  },

  /**
   * 수리과학 체험센터의 센터 전체 목록을 가져옵니다.
   * @returns {Promise<*[]>}
   */
  async getMathCenterList() {

    const URL_API_MATH_CENTER_LIST_ALL = "/api/external/v1/portalExternalCommonApi_mathCenterListAll";

    let param = {
      "orderBy" : "OID",
    }

    let response = await $nuxt.$axios.post( URL_API_MATH_CENTER_LIST_ALL, param );

    return response.data;
  },

  /**
   * systemOid 와 userId 로 사용자의 권한 목록을 호출합니다.
   *
   * @param systemOid
   * @param userId
   * @returns {Promise<*[]>}
   */
  async systemRoleByUserId( systemOid, userId ) {

    const URL_EXTERNAL_ROLE_BY_USER_ID = "/api/external/v1/portalExternalRoleApi_systemRoleByUserId";

    let param = {
      userId : userId,
      systemOid : systemOid
    }

    let result = [];

    await $nuxt.$axios.post( URL_EXTERNAL_ROLE_BY_USER_ID, param ).then( res => {

      if ( 200 === res.status && res.data.length > 0 ) {

        // console.log( res.data ); // [ { }, { }, ... ]

        result = res.data;
      }
    } );

    return result;
  },

  /**
   * 사용자의 LMS( 원격교육연수원, 교직원집합연수원 ) 내의 권한을 호출합니다.
   *
   * @param URL
   * @param userId
   * @returns {Promise<*[]>}
   */
  async getLmsRoleList( userId ) {

    let lmsRoleList = [];

    let contactRoleList = await this.systemRoleByUserId( PORTAL_CONSTANT.SYSTEM_OID.LMS_CONTACT, userId );
    let ontactRoleList = await this.systemRoleByUserId( PORTAL_CONSTANT.SYSTEM_OID.LMS_ONTACT, userId );

    if ( contactRoleList.length > 0 ) {
      // systemOid 값 부여
      contactRoleList.forEach( item => item.systemOid = PORTAL_CONSTANT.SYSTEM_OID.LMS_CONTACT );
      contactRoleList.forEach( item => lmsRoleList.push( item ) );
    }

    if ( ontactRoleList.length > 0 ) {
      // systemOid 값 부여
      ontactRoleList.forEach( item => item.systemOid = PORTAL_CONSTANT.SYSTEM_OID.LMS_ONTACT );
      ontactRoleList.forEach( item => lmsRoleList.push( item ) );
    }

    return lmsRoleList;
  },
}
export default portalExternalRole;
