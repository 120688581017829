import typeValidationUtils
  from '~/plugins/core/utils/validation/typeValidationUtils';
/**
 * form Validation 유틸
 */
const formValidationUtils = (() => {

  const { isEmpty } = typeValidationUtils;
  
  return {
    
    isNumber( num ) {

      if ( isEmpty( num ) ) {
        return false;
      }

      return /^[0-9]$/g.test( num );
    },

    isEmail( email ) {

      if ( isEmpty( email ) ) {
        return false;
      }

      const emailRegex = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
      return emailRegex.test( email );
    },

    /**
     * 연락처를 010 - xxxx - xxxx 처럼 3개로 받아서 검증할 경우 사용합니다.
     *
     * 앞 번호는 010,011,016,017,018,019 만 허용합니다.
     * 중간 번호는 3 자리 또는 4 자리를 허용합니다.
     * 뒷 번호는 4 자리만 허용합니다.
     *
     * 위 조건이 모두 맞아야 true 를 반환하고, 아닐 경우 false 를 반환합니다.
     *
     * @param phonePart1
     * @param phonePart2
     * @param phonePart3
     * @return {boolean}
     */
    isPhone( phonePart1, phonePart2, phonePart3 ) {

      if ( isEmpty( phonePart1 ) || isEmpty( phonePart2 ) || isEmpty( phonePart3 ) ) {
        return false;
      }

      const phone1RegExp = /^01([016789]?)$/;
      const phone2RegExp = /^([0-9]{3,4})$/;
      const phone3RegExp = /^([0-9]{4})$/;

      return ( phone1RegExp.test( phonePart1 ) &&
        phone2RegExp.test( phonePart2 ) &&
        phone3RegExp.test( phonePart3 )
      );

    },

    /**
     * 일반 연락처를 검증할 경우 사용합니다.
     *
     * 앞 번호는 010,011,016,017,018,019 또는 02 또는 030~099
     * 중간 번호는 3 자리 또는 4 자리를 허용합니다.
     * 뒷 번호는 4 자리만 허용합니다.
     *
     * 위 조건이 모두 맞아야 true 를 반환하고, 아닐 경우 false 를 반환합니다.
     *
     * @param phonePart1
     * @param phonePart2
     * @param phonePart3
     * @return {boolean}
     */
    isNormalPhone( phonePart1, phonePart2, phonePart3 ) {

      if ( isEmpty( phonePart1 ) || isEmpty( phonePart2 ) || isEmpty( phonePart3 ) ) {
        return false;
      }

      const phone1RegExp = /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})$/;
      const phone2RegExp = /^([0-9]{3,4})$/;
      const phone3RegExp = /^([0-9]{4})$/;

      // false && false && true => // false
      return ( phone1RegExp.test( phonePart1 ) &&
        phone2RegExp.test( phonePart2 ) &&
        phone3RegExp.test( phonePart3 )
      );
    },

    isName( name ) {

      if ( isEmpty( name ) ) {
        return false;
      }

      const nameExp = /^[a-zA-Z가-힣]+\s?[a-zA-Z가-힣]+\s?[a-zA-Z가-힣]+$/;
      return nameExp.test( name );
    },

    /**
     * 비밀번호 유효성 검사 - 정규식 사용
     *
     * @param {String} pwd - 검사할 비밀번호
     * @param {String} generateRule - system.config.pwd.generaterule 값
     * @return {boolean} - true : 검사 성공 , false : 검사 실패
     */
    isPwd( pwd , generateRule = "10~16" ) {

      if ( isEmpty( pwd ) ) {
        return false;
      }

      if ( "4~16" === generateRule ) {
        const pwdTester4_16 = /^.{4,16}$/;
        return pwdTester4_16.test(pwd);
      }
      else if ( "8~16" === generateRule ) {
        const pwdTester8_16 = /^((?=.*\d)(?=.*[~`!@#$%\\^&*()-]))(?=.*[A-Za-z]).{8,16}$/;
        return pwdTester8_16.test(pwd);
      }

      const pwdTester10_16 = /^((?=.*\d)(?=.*[~`!@#$%\\^&*()-]))(?=.*[A-Za-z]).{10,16}$/;
      return pwdTester10_16.test(pwd);
    },

    /**
     * 생년월일 validation
     *
     * @param {String} birthday - 생년월일 (형식은 YYYYMMDD or YYMMDD)
     * @param {number} checkBirthdayType - 생년월일 타입 : 6자리 또는 8자리 , 그 이외의 경우 무조건 8자리로 체크
     * @return {boolean} - true : 체크 성공 , false - 체크 실패
     */
    isBirthday( birthday , checkBirthdayType = 8 ) {

      const birtyDayType8 = /^(19[0-9][0-9]|20\d{2})(0[0-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])$/;
      const birtyDayType6 = /([0-9]{2}(0[1-9]|1[0-2])(0[1-9]|[1,2][0-9]|3[0,1]))/;

      return 6 === checkBirthdayType ? birtyDayType6.test( birthday ) : birtyDayType8.test( birthday );
    },

    /**
     * 도메인 validation
     *
     * @param {string} domain
     * @return {boolean}
     */
    isDomain( domain ) {

      const domainPattern = /^(?!:\/\/)([a-zA-Z0-9-_]+\.)+[a-zA-Z]{2,6}$/;
      return domainPattern.test(domain);
    },

    /**
     * url validation
     *
     * @param url
     * @return {boolean}
     */
    isUrl( url ) {

      const urlRegex = /^(https?:\/\/)([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(\/.*)?$/;
      return urlRegex.test( url );
    },

  };
})();

export default formValidationUtils;