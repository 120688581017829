<template>
  <div class="related-sites popup-zone" :class="[isPopupVisible ? 'visible' : '']" v-if="isPopupVisible">
    <!-- inner-content -->
    <div class="inner-content">
      <!-- 목록 -->
      <div class="detail-content">
        <div class="tit-area">
          <div class="tit-item">
            <h2>
              POPUP ZONE
            </h2>
          </div>
          <div class="web-visible">
            <!-- navigation btn -->
            <div class="navigation-btn-group">
              <!-- swiper navigation -->
              <div class="swiper-button-prev site-swiper-prev" slot="button-prev">
                <span class="material-icons-outlined">arrow_back_ios</span>
              </div>
              <div class="sub-icon">
                <span>|</span>
              </div>
              <!-- btn autoplay pause / play -->
              <div class="swiper-button-next site-swiper-next" slot="button-next">
                <span class="material-icons-outlined">arrow_forward_ios</span>
              </div>
            </div>
            <div class="button-play">
              <!-- pause -->
              <button v-show="popupSwiperPlay" class="swiper-button-pause" @click="popupSwiperPlay = false">
                <span class="material-icons-outlined">stop</span>
              </button>
              <!-- play -->
              <button v-show="!popupSwiperPlay" class="swiper-button-pause" @click="popupSwiperPlay = true">
								<span class="material-icons-round">
									play_arrow
								</span>
              </button>
            </div>
          </div>
        </div>
        <div class="list-area">
          <div class="list-wrapper">
            <swiper
              class="swiper list-swiper related-site-list"
              :options="relatedSitesListSwiperOption"
              ref="popupSwiper"
            >
              <!-- list-item -->
              <swiper-slide v-for="item in popupList" :key="item.popupOid">
                <div
                  v-if="isContentsType( item )"
                  class="list-item contents-box">
                  <!-- scroll area -->
                  <div class="scroll-element"
                       v-bar="{ preventParentScroll: true }">
                    <!-- el1 -->
                    <div>
                      <!-- 에디터에서 입력한 내용은 아래 contents에. -->
                      <div class="contents">
                        <div class="tc">
                          <p v-html="item.contents"></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else class="list-item">
                  <a @click="setImgUrl(item)">
                    <img alt="" :src="getImage(item)"/>
                  </a>
                </div>
              </swiper-slide>
            </swiper>
          </div>
        </div>
      </div>
      <div class="content-footer">
        <div class="btn-row">
          <button v-if="!ignoreCookie" class="btn-close-round" @click="hideToday()">
            <span class="txt">오늘 하루 열지 않기</span>
            <i class="material-icons">close</i>
          </button>
          <button class="btn-close-round" @click="closePopup()">
            <span class="txt"> 닫기</span>
            <i class="material-icons">close</i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";


export default {
  components: {},
  props: {
    popupList : {
      type: Array,
      required : false,
    },
    ignoreCookie : {
      type : Boolean,
      require : false,
      default : false,
    }
  },

  data() {
    return {
      // 관련 사이트 목록 스와이퍼 옵션
      relatedSitesListSwiperOption: {
        spaceBetween: 20,
        centerInsufficientSlides: true, // 센터 정렬
        slidesPerView: 4,     // 보여지는 슬라이드 갯수
        slidesPerGroup: 4,    // 슬라이드 숫자
        // slidesPerView: "auto",
        // centeredSlides: true,
        loopFillGroupWithBlank: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: true,
        },
        // loop: true,
        // loopedSlides: 1,
        // pagination : {
        // 	el        : ".site-swiper-pagination",
        // 	clickable : true,
        // },
        navigation: {
          nextEl: ".site-swiper-next",
          prevEl: ".site-swiper-prev",
        },

        breakpoints: {
          1024: {
            slidesPerView:  2.3,
            slidesPerGroup: 1,
            spaceBetween: 10,
          },

          865: {
            slidesPerView: 2.1,
            slidesPerGroup: 2,
            spaceBetween: 10,
          },
          430: {
            slidesPerView: 1.1,
            slidesPerGroup: 1,
            spaceBetween: 10,
          }

        }
      },

      // popupList: [],

      // swiper autoplay
      popupSwiperPlay: true,
      isPopupVisible: false,
    };
  },
  async fetch() {
    // await this.listPopup();
  },
  computed: {},
  watch: {
    // swiper autoplay
    popupSwiperPlay() {
      if (true === this.popupSwiperPlay) {
        this.$refs.popupSwiper.swiper.autoplay.start();
      }
      else {
        this.$refs.popupSwiper.swiper.autoplay.stop();
      }
    },

    isPopupVisible() {
      this.setScrollOverflow();
    },

    popupList() {
      if( this.popupList && this.popupList.length > 0 ) {
        this.isPopupVisible = true;

        if( !this.ignoreCookie ) {
          this.checkCookie();
        }

      }
      else {
        this.isPopupVisible = false;
        return;
      }
    },
  },
  mounted() {
    this.setScrollOverflow();
  },
  methods: {

    getImage(item) {

      if (this.$validate.isEmpty(item) || this.$validate.isEmpty(item.fileList)) {

        return;
      }

      // [YP20240403] 2024-04-03 썸네일 파일을 찾아 getIamge로 path 넘길 수 있게 수정
      const thumbnailFile = item.fileList.filter( file => this.$constant.FILE_TYPE.IMG !== file.fileType )[0];

      return this.$util.fileUtils.getThumbnailPath( thumbnailFile.storageFileUid, item.width + "_" + item.height );
    },

    closePopup() {
      this.$emit("close");
      this.isPopupVisible = false;
    },

    setScrollOverflow() {

      if (this.isPopupVisible) {
        document.querySelector('.wrapper').style.overflow = "hidden"
      }
      else {
        document.querySelector('.wrapper').style.overflow = ""
      }
    },

    // 오늘 하루 열지 않기
    hideToday() {

      Cookies.set(this.$constant.POPUP_HIDE_TODAY_LIST, true, {expires: 1});
      this.closePopup();
    },

    checkCookie() {

      if (Cookies.get(this.$constant.POPUP_HIDE_TODAY_LIST)) {
        this.closePopup();
      }
    },

    // async listPopup() {
    //
    // 	let param = {
    // 		popupViewTypeFlag: this.$constant.POPUP_TYPE.VIEW.LIST.KEY,
    // 	};
    //
    // 	await this.$axios.post(OPERATION_USER_LIST, param).then(res => {
    //
    // 		if (this.$validate.isEmpty(res.data)) {
    // 			this.isPopupVisible = false;
    // 			return;
    // 		}
    //
    // 		this.popupList = res.data;
    //
    // 		this.isPopupVisible = true;
    // 		this.checkCookie();
    // 	});
    // },

    /* 링크 타입이 새창인지, 페이지 이동인지 구분하여 이동 */
    setImgUrl(item) {

      if (this.$validate.isEmpty(item) || this.$validate.isEmpty(item.linkUrl)) {
        return;
      }

      // [YP20240412] 2024-04-12 linkTypeFlag 값을 가지고 있으면 openWindowYn값으로 변경
      if ( this.$validate.isNotEmpty( item.linkTypeFlag ) ) {
        if (this.$constant.POPUP_TYPE.LINK.PAGE === item.linkTypeFlag ) {
          item.openWindowYn = this.$constant.POPUP_TYPE.OPEN_WINDOW_YN.NO;
        }
        else {
          item.openWindowYn = this.$constant.POPUP_TYPE.OPEN_WINDOW_YN.YES;
        }
      }

      // 페이지 이동
      // [YP20240412] 2024-04-12 linkTypeFlag -> OpenWindowYn으로 변경
      if ( this.$constant.POPUP_TYPE.OPEN_WINDOW_YN.NO === item.openWindowYn ) {
        // 썸네일 클릭시 이미지를 여는 경우 popupOid를 함께 전달합니다.
        if ( this.$constant.POPUP_TYPE.MOVE.IMAGE === item.moveTypeFlag ) {
          window.location.href = item.linkUrl + "?popupOid=" + item.popupOid;
          return;

        }

        window.location.href = item.linkUrl;
        return;
      }
      else if ( this.$constant.POPUP_TYPE.OPEN_WINDOW_YN.YES === item.openWindowYn ) {  // 새창
        if ( this.$constant.POPUP_TYPE.MOVE.IMAGE === item.moveTypeFlag ) {
          this.$validate.isMobile() ? this.mobileNoGuide(item.linkUrl + "?popupOid=" + item.popupOid ) : window.open( item.linkUrl + "?popupOid=" + item.popupOid );
          return;

        }

        this.$validate.isMobile() ? this.mobileNoGuide( item.linkUrl ) : window.open( item.linkUrl );
      }
    },

    mobileNoGuide( url ) {
      if ( url.includes("GwNoteDataBoxGuide") ) {
        return this.$util.rsAlertUtils.rsAlert( { contents: "해당 기능은 모바일에서 지원하지 않으므로 PC 환경에서 확인해주시기를 바랍니다." } );
      }

      window.open( url );
    },

    isContentsType( item ) {
      return this.$validate.isNotEmpty( item ) ?
        this.$validate.isNotEmpty( item.contents )
        && this.$constant.POPUP_TYPE.CONTENTS.EDITOR === item.popupContentsTypeFlag
        : this.$validate.isNotEmpty( item.contents );
    },
  },
};
</script>
<style></style>
