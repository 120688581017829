<template>
  <div class="wrapper">
    <div class="loading-container" v-if="$fetchState.pending">
      <TheLoading/>
    </div>
    <!--주 컨테이너 -->
    <div class="main-container manager-container">
      <header id="manager-header" class="manager-header">
        <div class="inner">
          <div>
            <div class="logo-area">
              <img src="~@/assets/images/common/logo/logo.png" alt="#" class="logo">
            </div>
            <nav id="manager-gnb" class="manager-gnb">
              <ul class="gnb-menu">
                <li
                  v-for="(depth1, depth1Index) in currentMenuList"
                  :key="depth1Index"
                  :class="getMenuActive( depth1 )"
                >
                  <a class="link" v-text="depth1.name" @click="selectMenu( depth1 )"></a>
                  <!-- 수리과학정보체험센터에서만 활성화-->
                  <ul
                    v-if="$constant.SYSTEM_OID.MATH === depth1.menuOid && $validate.isNotEmpty( mathCenterList )"
                    class="sub-menu"
                  >
                  </ul>
                </li>
                <!-- TODO MIN 추후 개발 시 삭제 필요 ( 개발 전 까지 주석 처리 하겠습니다. ) -->
<!--                <li>-->
<!--                  <a class="link" v-text="'단축 URL'" @click="onUrlMove"></a>-->
<!--                </li>-->
              </ul>
            </nav>
          </div>
          <div class="user-info">
            <div class="name-mode">
              <div class="flex-item-center">
                  <span class="user-name">
                    <!-- name-pre 여기에 관리자 유형 선택 -->
                    <span class="name-pre" v-text="loginUser.userName"></span>
                    <span class="name-main color-primary"></span>님
                  </span>
                <!-- 즐겨찾기 리스트 드롭 다운 영역-->
                <div>
                  <el-dropdown class="flex" v-if="false">
                      <span class="el-dropdown-link">
                        <div class="btn-star-box">
                          <el-button class="btn-star el-icon-star-off no-border txt sm align-center"
                          ></el-button>
                          <!--                                   :class="{ 'el-icon-star-on': isActive }"-->
                        </div>
                      </span>
                    <el-dropdown-menu slot="dropdown" class="manager-dropdown">
                      <div>
                        <span class="block bold tit xsm">자주 찾는 메뉴</span>
                        <span>자주 찾기 등록한 메뉴 목록 입니다.</span>
                      </div>
                      <el-dropdown-item class="mt10">1
                        <span class="material-icons">delete_forever</span>
                      </el-dropdown-item>
                      <el-dropdown-item>2
                        <span class="material-icons">delete_forever</span>
                      </el-dropdown-item>
                      <el-dropdown-item>3
                        <span class="material-icons">delete_forever</span>
                      </el-dropdown-item>
                      <el-dropdown-item>4
                        <span class="material-icons">delete_forever</span>
                      </el-dropdown-item>
                      <el-dropdown-item>5
                        <span class="material-icons">delete_forever</span>
                      </el-dropdown-item>
                      <el-dropdown-item>6
                        <span class="material-icons">delete_forever</span>
                      </el-dropdown-item>
                      <el-dropdown-item>7
                        <span class="material-icons">delete_forever</span>
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
              <el-button type="st-text is-round" class="size-xsm" @click="$router.push('/portal/user/main/user_main')">
                user mode
              </el-button>
            </div>
            <!-- 로그인 버튼 -->
            <!-- 관리자 페이지는 관리자로 로그인했을 경우에만 접근 가능하므로
               항상 로그인되어 있다는 가정 하에 로그아웃 버튼으로 사용하겠습니다. -->
            <el-button class="" @click="$nuxt.$store.dispatch('common/login/logout')">
              <i class="material-icons">power_settings_new</i>
            </el-button>
          </div>
        </div>
      </header>
      <div class="left-area" :class="{'sidebar-active' : sideBarActive}">
        <the-manager-side-bar ref="sideBar" :side-bar-menu="sideBarMenu" v-if="$validate.isNotEmpty( sideBarMenu )"/>
        <!-- 매니저 사이드바 -->
        <div class="btn-area">
          <el-button @click="sideBarActive = !sideBarActive">
            <i class="material-icons">arrow_back_ios</i>
          </el-button>
        </div>
      </div>
      <div class="right-area content-area" :class="{'sidebar-active' : sideBarActive}">
        <!-- 페이지별 내용 -->
        <nuxt/>
        <!-- footer -->
        <the-manager-footer/>
      </div>
    </div>
  </div>
</template>
<script>
import TheLoading from "~/components/common/loading/TheLoading";
import TheManagerSideBar from "~/pages/portal/manager/layout/TheManagerSideBar";
import TheManagerFooter from "~/pages/portal/manager/layout/TheManagerFooter";
import { mapGetters } from "vuex";

const MANAGER_IFRAME = "/portal/manager/iframeMgmt/managerIframe";
const URL_MATH_LIST_BY_ROLE = "/api/portlet/v1/portalPortletApi_mathListByRole";
const URL_LMS_MENU_LIST = "/api/portlet/v1/portalPortletApi_lmsMenuList";

export default {
  name       : "managerLayout",
  components : {
    TheLoading,
    TheManagerFooter,
    TheManagerSideBar
  },
  data() {
    return {
      sideBarActive : true,
      isActive : false,

      activeMenu : {},
      sideBarMenu : {},

      mathCenterList : [],
    }
  },

  computed : {
    ...mapGetters( {
      adminMenuList : "portal/menuRole/adminMenuListByRole",
      loginUser : "common/login/loginUserInfo",
    }),

    currentMenuList() {

        const cloneAdminMenuList = _.cloneDeep( this.adminMenuList );

        // display가 Y인 값만 가져옵니다.
        return this.$portalUtil.portalRoleMenuUtils.getOnlyDisplayMenuList( cloneAdminMenuList, 1 );
    }
  },

  fetch() {

  },

  watch : {

    activeMenu() {

      if ( this.$validate.isNotEmpty( this.adminMenuList ) ) {
        this.sideBarMenu = this.activeMenu;

        if ( this.$validate.isEmpty( this.sideBarMenu.childTreeNodeList ) ) {

          this.selectMenu( this.sideBarMenu );
        }
      }

    },

    async adminMenuList() {

      if ( this.$validate.isNotEmpty( this.adminMenuList ) && this.$route.query.menuOid ) {
        let menu = this.adminMenuList.find( adminMenu => adminMenu.menuOid === this.$route.query.menuOid );
        await this.selectMenu( menu );
      }
    }
  },

  methods : {


    // 관리자 메뉴 선택시
    async selectMenu( menu ) {
      // 외부시스템(iframe) 타입일 경우 타 시스템 호출 ( 원격, 블렌디드, 설문, 전자책, 수리과학 )
      if ( this.$constant.MENU_TYPE_FLAG.IFRAME.KEY === menu.menuTypeFlag ) {

        await $nuxt.$store.dispatch( "portal/iframe/setDomainData", menu );

        let param = {
          id 				: this.loginUser.userId,
          likeSearch 	: false,
        }

        // 수리과학의 경우 권한 정보 채워옴
        if ( this.$constant.SYSTEM_OID.MATH === menu.menuOid ) {
          param.roleSearch = true;
          param.url = "/api/portlet/v1/portalPortletApi_mathUser";
        }

        // 원격연수 & 블렌디드인 경우
        if ( this.$constant.SYSTEM_OID.LMS_CONTACT === menu.menuOid || this.$constant.SYSTEM_OID.LMS_ONTACT === menu.menuOid ) {
          param.url = "/api/portlet/v1/portalPortletApi_lmsUser";
        }

        // 설문인 경우
        if ( this.$constant.SYSTEM_OID.SURVEY === menu.menuOid ) {
          param.url = "/api/portlet/v1/portalPortletApi_surveyUser";
        }

        // 전자책인 경우
        if ( this.$constant.SYSTEM_OID.EBOOK === menu.menuOid ) {
          param.userOid = this.$store.state.common.login.loginUser.userOid;
          param.url = "/api/portlet/v1/portalPortletApi_ebookUser";
        }

        // 노트인 경우
        if ( this.$constant.SYSTEM_OID.NOTE === menu.menuOid ) {
          param.oid = this.$store.state.common.login.loginUser.userOid;
          param.url = "/api/portlet/v1/portalPortletApi_noteUser";
        }

        await this.getUser( param , menu );

        return;
      }


      let second = {}

      // 교육포털, 홈페이지, 구글워크스페이스
      if ( this.$validate.isNotEmpty( menu.childTreeNodeList ) ) {
        second = menu.childTreeNodeList[0];

        this.sideBarMenu = menu;
        this.sideBarActive = true;
      }

      this.$refs.sideBar.moveMenu( second, menu );
    },

    // 타 시스템 선택시 사용
    async getUser( param, menu ) {

      await this.$axios.post( param.url , param ).then( res => {

        let user = res.data;

        if ( this.$validate.isEmpty( user ) ) {

          this.$util.rsAlertUtils.rsAlert( { title: "권한 없음", contents: "관리자 페이지 접근 권한이 없습니다." } );
          return;
        }

        // 수리과학의 경우 권한에 따라 센터 값을 정해주어야 함
        if ( this.$constant.SYSTEM_OID.MATH === menu.menuOid ) {

          if ( this.$validate.isEmpty( user.userRoleList ) ) {
            this.$util.rsAlertUtils.rsAlert( { title: "권한 없음", contents: "관리자 페이지 접근 권한이 없습니다." } );
            return;
          }

          let centerOidList = this.checkCenter( user.userRoleList );

          if ( this.$validate.isEmpty( centerOidList ) ) {
            this.$util.rsAlertUtils.rsAlert( { title: "권한 없음", contents: "관리자 페이지 접근 권한이 없습니다." } );
            return;
          }

          user.centerOidList = centerOidList;

        }

        this.getAdminMenuList( user, menu );
      } )
    },

    checkCenter( roleList ) {

      let managerRoleInfo = roleList.find( roleInfo => -1 < roleInfo.oid.indexOf( 'INTGR' ) );

      let centerOidList = [];

      if ( this.$validate.isNotEmpty( managerRoleInfo ) ) {
        centerOidList = managerRoleInfo.customField1.split(",");
      }
      else {
        roleList.forEach( roleInfo => centerOidList.push( roleInfo.customField1 ) );
        // 중복 값 제거하기 위해 set 사용
        centerOidList = [...new Set(centerOidList)];
      }

      return centerOidList
    },

    async getAdminMenuList( user, menu ) {

      let param = {};

      // 원격, 블렌디드
      if ( this.$constant.SYSTEM_OID.LMS_CONTACT === menu.menuOid || this.$constant.SYSTEM_OID.LMS_ONTACT === menu.menuOid ) {
        this.setLmsParam( user, param, menu.menuOid );
      }

      // 설문 - return이 있는 이유는 menu관리를 하지 않기 때문
      if ( this.$constant.SYSTEM_OID.SURVEY === menu.menuOid ) {

        let clone = _.cloneDeep( menu );

        this.setSurveyMenuList( clone );

        this.sideBarMenu = clone;
        this.sideBarActive = true;

        this.$router.push( {
          path : MANAGER_IFRAME,
          query : { menuOid : menu.menuOid }
        });

        return;
      }

      // 전자책 - return이 있는 이유는 menu관리를 하지 않기 때문
      if ( this.$constant.SYSTEM_OID.EBOOK === menu.menuOid ) {

        let clone = _.cloneDeep( menu );

        this.setEBookMenuList( clone );

        this.sideBarMenu = clone;
        this.sideBarActive = true;

        this.$router.push( {
          path : MANAGER_IFRAME,
          query : { menuOid : menu.menuOid }
        });

        return;
      }

      // 수리과학
      if ( this.$constant.SYSTEM_OID.MATH === menu.menuOid ) {

        this.setMathParam( user, param );
      }

      // 노트 - return이 있는 이유는 menu관리를 하지 않기 때문
      if ( this.$constant.SYSTEM_OID.NOTE === menu.menuOid ) {

        let clone = _.cloneDeep( menu );

        this.setNoteMenuList( clone );

        this.sideBarMenu = clone;
        this.sideBarActive = true;

        this.$router.push( {
          path : MANAGER_IFRAME,
          query : { menuOid : menu.menuOid }
        });

        return;
      }


      await this.$axios.post( param.url, param ).then( res => {

        if ( this.$validate.isEmpty( res.data ) ) {
          return;
        }

        let menuList = res.data;
        let clone = _.cloneDeep( menu );
        // console.log( ' menuList : ', menuList );

        if ( this.$constant.SYSTEM_OID.MATH === menu.menuOid ) {
          clone.childTreeNodeList = menuList;
          this.setMathMenuList( clone, user.centerOidList );
        }

        if ( this.$constant.SYSTEM_OID.LMS_CONTACT === menu.menuOid || this.$constant.SYSTEM_OID.LMS_ONTACT === menu.menuOid ) {

          clone.childTreeNodeList = menuList[0].childTreeNodeList;
        }

        this.getMenuActive( menu );
        this.sideBarMenu = clone;
        this.sideBarActive = true;

        this.$router.push( {
          path :MANAGER_IFRAME,
          query : { menuOid : menu.menuOid }
        });

      });
    },

    getMenuActive( menu ) {

      const pathName = this.$route.fullPath;

      if ( -1 < pathName.indexOf( menu.url ) ) {
        this.activeMenu = menu;
        return "active";
      }
      else if ( -1 < pathName.indexOf( menu.menuOid ) && this.$validate.isNotEmpty( this.$route.query.menuOid ) ){
        this.activeMenu = menu;
        return "active";
      }

    },

    // 즐겨찾기 별 클릭 이벤트
    starToggle() {
      this.isActive = !this.isActive;

      // 해당 메뉴에 대한 즐겨찾기 생성 or 삭제
      let param = {
        targetOid: "",
        targetObject: this.$constant.MENU_OBJECT_TYPE,
        inputUser: this.loginUser.userOid,
      };

      let url = "";

      // 즐겨찾기 active 값이 true이면 생성, 아닐 경우 삭제
      if (this.isActive) {
        url = "/api/manager/v1/portalManagerOperateApi_insertFavorite";
      }
      else {
        url = "/api/manager/v1/portalManagerOperateApi_deleteFavorite";
      }

      this.$axios.post(url, param).then(res => {

      });
    },

    setMathParam( user, param ) {

      let userRoleInfoList = user.userRoleList;

      let currentCenter = ""

      if ( this.$validate.isNotEmpty( user.centerOidList ) ) {
        currentCenter = user.centerOidList[0];
      }

      // let currentCenter = "AREA0000001";

      if ( this.$validate.isEmpty( userRoleInfoList ) ) {
        return;
      }

      let intgrRole = userRoleInfoList.find( roleInfo => -1 < roleInfo.oid.indexOf( 'INTGR' ) );

      if ( this.$validate.isEmpty( intgrRole ) ) {
        // 현재 센터 권한리스트 검색
        let filterListByCenter = userRoleInfoList.filter( roleInfo => currentCenter === roleInfo.customField1 )

        if ( this.$validate.isEmpty( filterListByCenter ) ) {
          return;
        }

        param.roleOidSet = [ ...new Set( filterListByCenter.map( roleInfo => roleInfo.oid ) ) ]
      }
      else {
        param.roleOid = intgrRole.oid;
      }

      // 센터별로 즐겨찾기 리스트 구분하기 위하여 사용
      param.targetOid = currentCenter;

      param.url = URL_MATH_LIST_BY_ROLE; // 수리과학 직접 호출 -> 포탈 거쳐서 호출하도록 변경

    },

    setLmsParam( user, param, menuOid ) {
      // const URL_MENU_LIST = process.env.SYSTEM.LMS.URL + "/lms/api/v1/lmsMenuApi_listMenuTree";

      if ( this.$constant.SYSTEM_OID.LMS_ONTACT === menuOid ) {
        param.className = "ontactAdminMenu";
      }

      if ( this.$constant.SYSTEM_OID.LMS_CONTACT === menuOid ) {
        param.className = "contactAdminMenu";
      }

      param.useYn = this.$constant.FLAG_YN.YES;
      // param.url = URL_MENU_LIST;
      param.url = URL_LMS_MENU_LIST; // LMS 직접 호출 -> 포탈 거쳐서 호출하도록 변경
    },

    setMathMenuList( clone, centerOidList ) {


      clone.centerOidList = centerOidList;

      let mainMenu =  {};
      mainMenu.name = "메인";
      mainMenu.url = "/mathSciences/manager/main/manager_main";
      mainMenu.menuTypeFlag = this.$constant.MENU_TYPE_FLAG.URL.KEY;


      clone.childTreeNodeList.unshift( mainMenu );

    },

    setSurveyMenuList( clone ) {
      let childMenuList = [];

      let child = {};
      child.name = "공지사항";
      child.url = "/survey/notice/surveyNotice_list";
      child.menuTypeFlag = this.$constant.MENU_TYPE_FLAG.URL.KEY;

      childMenuList.push( child );

      child = {};
      child.name = "자료실";
      child.url = "/survey/dataRoom/surveyDataRoom_list";
      child.menuTypeFlag = this.$constant.MENU_TYPE_FLAG.URL.KEY;

      childMenuList.push( child );

      child = {};
      child.name = "관리";
      child.url = "/survey/manager/survey_manager";
      child.menuTypeFlag = this.$constant.MENU_TYPE_FLAG.URL.KEY;

      childMenuList.push( child );

      clone.childTreeNodeList = childMenuList;
    },

    setEBookMenuList( clone ) {

      let childMenuList = [];

      let child = {};

      child.name = "현황 관리";
      child.url = "/ebook/ebookMgnt/ebookMgnt_status";
      child.menuTypeFlag = this.$constant.MENU_TYPE_FLAG.URL.KEY;

      childMenuList.push( child );

      child = {};
      child.name = "통계 관리";
      child.url = "/ebook/ebookMgnt/ebookMgnt_statistics";
      child.menuTypeFlag = this.$constant.MENU_TYPE_FLAG.URL.KEY;

      childMenuList.push( child );

      child = {};
      child.name = "회원관리";
      child.url = "/ebook/memberControl/ebook_memberControl";
      child.menuTypeFlag = this.$constant.MENU_TYPE_FLAG.URL.KEY;

      childMenuList.push( child );

      child = {};
      child.name = "팝업관리";
      child.url = "/ebook/popupMgnt/popupMgnt_list";
      child.menuTypeFlag = this.$constant.MENU_TYPE_FLAG.URL.KEY;

      childMenuList.push( child );

      clone.childTreeNodeList = childMenuList;
    },
    setNoteMenuList( clone ) {

      const menuNameToUrlObject = {
        "분류체계 관리"     : "/gwnote/manager/classificationMgmt/classificationMgmt",
        "지원기관 관리"     : "/gwnote/manager/educationalInstMgmt/educationalInstMgmt",
        "연구노트 관리"     : "/gwnote/manager/researchNoteMgmt/researchNoteMgmt",
        "연구자료 관리"     : "/gwnote/manager/researchDataMgmt/researchDataMgmt",
        "검증위원 관리"     : "/gwnote/manager/verificationMemberMgmt/verificationMemberMgmt",
        "환경설정"         : "/gwnote/manager/managerSetting/managerSetting",
        "파일 관리"        : "/gwnote/manager/fileMgmt/fileMgmt",
        "새 버전 안내 관리" : "/gwnote/manager/bulletinMgmt/newVersionGuideMgmt/newVersionGuideMgmt"
      }

      const childMenuList = Object.keys( menuNameToUrlObject ).map( key => {
        const child = {
          name : key,
          url : menuNameToUrlObject[key],
          menuTypeFlag : this.$constant.MENU_TYPE_FLAG.URL.KEY,
        };
        return child;
      } )

      clone.childTreeNodeList = childMenuList;

    },


  }
}
</script>
<style scoped>
</style>
