import portalFormatUtils from '~/plugins/site/portal/utils/portalFormatUtils';
import portalValidationUtils from '~/plugins/site/portal/utils/portalValidationUtils';
import portalExcelUtils from '~/plugins/site/portal/utils/portalExcelUtils';
import portalUserUtils from '~/plugins/site/portal/utils/portalUserUtils';
import portalMenuUtils from '~/plugins/site/portal/utils/portalMenuUtils';
import portalStatsUtils from '~/plugins/site/portal/utils/portalStatsUtils';
import portalRoleMenuUtils
  from '~/plugins/site/portal/utils/portalRoleMenuUtils';

/**
 * 사이트 Utils
 *
 */
const portalUtils = {

  // 포털 format 유틸 관련
  portalFormatUtils,

  // 포털 validation 유틸 관련
  portalValidationUtils,

  // 포털 excel 유틸 관련
  portalExcelUtils,

  // 포털 사용자 유틸 관련
  portalUserUtils,

  // 포털 메뉴 유틸 관련
  portalMenuUtils,
  
  // 포털 통계 유틸
  portalStatsUtils,

  // 포털 권한 메뉴 유틸
  portalRoleMenuUtils,

}

// 사용법
// vue나 js에서 this.$constant.PAGE_DISPLAY_COUNT
export default (context, inject) => {

  inject( "portalUtil", portalUtils )

};
