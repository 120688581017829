<template>
  <div class="site-map web-visible"
       v-bind="$attrs">
    <div class="division-inner">
      <!-- title 영역 -->
      <div class="division-item is-tit">
        <div class="link-area">
          <ul class="link-list">
            <li v-if="isLogggedIn">
              <a class="link" href="/portal/user/myPage/personalInfo/personalInfo_edit">마이페이지</a>
            </li>
            <li>
              <a class="link"
                 :href="'/portal/user/customerService/privatePolicy/personalInformation/personalInformation'">고객센터</a>
            </li>
          </ul>
        </div>
        <div class="tit-area">
          <span class="tit">사이트맵</span>
          <div @click="removeMediaClass">
            <slot name="options"/>
          </div>
        </div>
      </div>
      <!-- content 영역 -->
      <div class="division-item">
        <!-- right -->
        <div class="inner">
          <div class="inner-content">
            <!-- options -->
            <slot name="options"/>
            <div class="snb">
              <slot name="snb-menu"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="site-map-footer">
      <img src="~@/assets/images/common/logo/logo.png" alt="강원특별자치도교육청교육과학정보원">
    </div>
  </div>
</template>
<script>
export default {
  name: "TheSiteMap",

  computed: {
    // 로그인되어있으면 true
    isLogggedIn() {
      return this.$portalAuth.isLogin();
    },
  },
  methods: {
    // 특정 해상도 html font-size 조정
    removeMediaClass() {
      document.querySelector( 'html' ).classList.remove( 'is-media' );
    }
  },
}
</script>
<style scoped>
</style>
