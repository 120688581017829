import swal from 'sweetalert'; // sweetalert1 말고 sweetalert2 사용
import typeValidationUtils
  from '~/plugins/core/utils/validation/typeValidationUtils';


/**
 * Alert 관련 유팅
 */
const rsAlertUtils = (() => {

  const { isEmpty, isNotEmpty } = typeValidationUtils;

  /**
   * @typedef {"warning" | "error" | "info" | "success" } AlertState
   */

  return {

    /**
     * common의 swalWithOptions
     * 추후에 모두 rsAlert으로 변경해야합니다.
     *
     * @param title
     * @param contents
     * @param state
     * @param confirmButton
     * @param cancelButton
     * @param htmlElement
     * @return {Promise<any>}
     */
    swalWithOptions( title, contents, state, confirmButton, cancelButton , htmlElement ) {

      return this.rsAlert( {
        title,
        contents,
        state,
        confirmButton,
        cancelButton,
        useCancelBtn: true,
        htmlElement
      } )
    },

    /**
     * common의 confirmSwal
     * 추후에 모두 rsAlert으로 변경해야합니다.
     *
     * @param title
     * @param contents
     * @param state
     * @return {Promise<any>}
     */
    confirmSwal( title, contents, state ) {

      return this.rsAlert({
        title,
        contents,
        state
      })
    },

    /**
     * @param {Object} [params] - 매개변수 객체
     * @param {string} [params.title=""] - 알림 제목
     * @param {string} [params.contents=""] - 알림 내용
     * @param {AlertState} [params.state="warning"] - 알림 상태 (예: "warning", "error", "info", "success"). 기본값은 "warning"
     * @param {string} [params.confirmButton] - 확인 버튼 텍스트
     * @param {string} [params.cancelButton] - 취소 버튼 텍스트
     * @param {boolean} [params.useCancelBtn=false] - 취소 버튼 사용 여부
     * @param {HTMLElement} [params.htmlElement] - 추가 HTML 콘텐츠
     * @param {string} [params.customClass=""] - 커스텀 클래스
     */
    rsAlert( params ) {

      const {
        title = "",
        contents= "",
        state= "warning",
        confirmButton,
        cancelButton,
        useCancelBtn = false,
        htmlElement,
        customClass = "",
      } = params;

      let modeState = false;
      if ( state === "warning" || state === "error" ) {
        modeState = true;
      }

      return swal( {
        title      : title,
        text       : contents,
        icon       : state,
        dangerMode : modeState,
        buttons    : {
          confirm : {
            text : isEmpty( confirmButton ) ? "확인" : confirmButton,
            value : true,
          },
          cancel  : {
            text : isEmpty( cancelButton ) ? "닫기" : cancelButton,
            value : false,
            visible : useCancelBtn || isNotEmpty( cancelButton )
          },
        },
        content    : htmlElement,
        class      : customClass,
      } );

    },

  };
})();

export default rsAlertUtils;